import { useTranslation } from "react-i18next";
import Header from "../UI/Header"
import ProjectGuideCard from "../AboutPage/ProjectGuideCards";
import Footer from "../Footer/Footer";
import { useState } from "react";
import ProjectGuide2Card from "../AboutPage/ProjectGuide2Cards";

export default function ProjectGuidePage() {
    const { t } = useTranslation();

    const [page, setpage] = useState("ProjectGuide")

    return (
        //about area start
        <section className='w-full bg-[#eeece1]'>
            <section className="bg-[#0A4D69] h-[50vh] flex flex-col  relative">
                <Header />
            </section>
            <div className="w-[100%]  bg-[#EEEDE5]  " style={{marginTop:'-300px'}}>
                <div className="w-[80%] mt-20 mx-auto flex flex-col gap-14 relative z-80">
                    <h3
                        className="text-4xl md:text-6xl text-center text-white tracking-wide z-100"
                        style={{
                            zIndex: "100",
                        }}
                    >{
                        page === "ProjectGuide" ? t("ProjectGuide.PROJECT GUIDE first edition") : t("ProjectGuide2.PROJECT GUIDE first edition")
                    }

                        {/* {t("ProjectGuide.PROJECT GUIDE first edition")} */}
                    </h3>
                    <div className="flex flex-col gap-10  mt-10 lg:mt-0">
                        <img
                            src="/images/projectguideBanner.png"
                            style={{
                                zIndex: "100"
                            }}
                        />
                    </div>
                </div>
                <div className="w-full flex flex-col items-center gap-24">
                    {page === "ProjectGuide" ?
                        <ProjectGuideCard page={page} setpage={setpage} />
                        :
                        <ProjectGuide2Card page={page} setpage={setpage} />

                    }

                </div>
                <Footer />
            </div>

        </section>



    )
}



