import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  AccordionHeader,
} from "../shadcn/accordion";
import Footer from "../Footer/Footer";
import Header from "./Header";

import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { CiCircleMinus } from "react-icons/ci";
import { CiCirclePlus } from "react-icons/ci";
import { getAllFaq } from "../../Api";

const FAQ = () => {
  const { t , i18n } = useTranslation();

  const [openIndex, setOpenIndex] = useState(null);
  const [allFaq, setAllFaq] = useState([]);

  async function getFaq() {
    let data = await getAllFaq();
    // console.log(data?.data?.data)
    setAllFaq(data?.data?.data);
  }
  useEffect(() => {
    getFaq();
  }, []);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const items = [
    {
      title: "Is there a free trial available?",
      content:
        "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
    },
  ];

  return (
    <section className="w-full bg-[#eeece1]">
      <section className="bg-[#0A4D69] h-[50vh] flex flex-col  relative">
        <Header />
      </section>
      <div className="w-[100%]  bg-[#EEEDE5]  " style={{marginTop:'-300px'}}>
        <div className="w-[80%] mx-auto flex flex-col gap-14 relative z-80 mt-20 max-md:mt-14 max-sm:mt-6">
          <h3
            className={`${i18n.language === 'ar' ? 'cairo' : 'manrope'} text-6xl text-center text-white tracking-wide z-100`}
            style={{
              zIndex: "100",
            }}
          >
            {t("FAQ1.FaqHeading")}
          </h3>
          <div
            className="w-[100%] py-10 bg-white rounded-lg z-100"
            style={{ marginTop: "-20px"}}
          >
            <div className="w-[90%] mx-auto flex flex-col items-center gap-28">
              <Accordion type="single" collapsible className="min-w-[100%]">
                {allFaq?.map((item, index) => (
                  <AccordionItem value={index + 1}>
                    <AccordionTrigger onClick={() => handleToggle(index)}>
                      <div className="flex items-center justify-between w-full">
                        <span className={`${i18n.language === 'ar' ? 'cairo' : 'manrope'} text-2xl font-normal text-[#101828]`}>
                          {item?.title}
                        </span>
                        {openIndex === index ? (
                          <CiCircleMinus />
                        ) : (
                          <CiCirclePlus />
                        )}
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      <span className="text-[#084F679C] font-extrabold text-[18px]">
                        {item?.description}
                      </span>
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>

              {/* <Accordion type="single" collapsible className="min-w-[100%]">
                    {items.map((item, index) => (
                        <AccordionItem value={index}>
                            <AccordionTrigger onClick={() => handleToggle(index)}>
                                <div className="flex items-center justify-between w-full">
                                    <span>{item.title}</span>
                                    {openIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                                </div>
                            </AccordionTrigger>
                            <AccordionContent>
                                {item.content}
                            </AccordionContent>
                        </AccordionItem>
                    ))}
                </Accordion> */}
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col items-center mt-24 gap-24">
          <Footer />
        </div>
      </div>
    </section>
  );
};

export default FAQ;
