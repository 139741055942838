import React, { useEffect, useState } from 'react'
import Aboutfootertoparea2 from "../Components/Aboutfootertoparea2"
import Navbar from '../Components/Nav_Form/Navbar'
import success from "../assets/img/success.svg"
import { useLocation, useNavigate } from 'react-router-dom';
import { paymentCallbackApi, updatePaymentApi } from '../Api';
import { useTranslation } from 'react-i18next';
import { RxCross2 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";
import Header from '../Components/UI/Header';
import { useFormik } from 'formik';
import * as yup from "yup";
import toast from 'react-hot-toast';
import axios from 'axios';

const PaymentSuccess = () => {
  const location = useLocation();
  const [queryParams, setQueryParams] = useState({});
  const navigate = useNavigate()
  const { t } = useTranslation()
  async function makePayment(data) {
    try {

      let res = await paymentCallbackApi(data)
      console.log(res)
    } catch (error) {
      console.log(error)
    }

  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    console.log(params)
    makePayment(params)
    setQueryParams(params);
  }, [location.search]);



  const formik = useFormik({
    initialValues: {
      paymentProof: '',
    },
    validationSchema: yup.object({
      paymentProof: yup.string().required("Payment Proof is required"),
    }),
    onSubmit: async (values) => {

      // values.user = userInfo?._id; // Use user ID from local storage
      try {
        let res = await updatePaymentApi(values,queryParams?.id );
        if(res?.data)
          
        toast.success(t("Payment Proof Upload Successfully"));
        navigate("/my-donations", { replace: true });


      } catch (error) {
        console.log(error);
      }
    }
  });

  const uploadImage = async (e, fieldType) => {
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);

      try {
        let data = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/upload/singleFileUpload`, formData, {
          headers: {
            "Content-Type": 'multipart/form-data',
          },
        });
        formik.setFieldValue(fieldType, data.data.fileUrl);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };
  return (
    <div>
      <section className="bg-[#0A4D69] h-[10vh] flex flex-col  relative">
        <Header />
      </section>
      <div className='flex justify-center item center py-20'>
        <div>
          <div className='flex justify-center item center'>

            {
              queryParams.status == "failed" ? <div className='flex gap-2 item-center'>

                <RxCross2 className='text-red-700 mt-2 text-[2rem]' />    <span className='mt-3'>  {t(`Your Payment is Failed`)}</span>
              </div> :
                <div className='flex flex-col gap-2 item-center'>
                  <div>
                    <TiTick className='text-green-700 mt-2 text-[2rem]' />    <span className='mt-3'>  {t(`Your Payment is successfully completed`)}</span>
                  </div>
                  <div className='mt-10'>
                    <form onSubmit={formik.handleSubmit}>

                      <div className='text-left mb-4'>
                        <h3 className='mb-2'>Upload Payment Proof</h3>
                        <input
                          type="file"
                          name="paymentProof"
                          onChange={(e) => uploadImage(e, "paymentProof")}
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        {formik.touched.paymentProof && formik.errors.paymentProof ? (
                          <p className="text-red-500">{formik.errors.paymentProof}</p>
                        ) : null}
                      </div>
                      <button
                    type="submit"
                    className="bg-blue-500 text-white rounded-md py-2 px-4 mt-4 w-full hover:bg-blue-600 transition duration-200"
                  >
                    Upload
                  </button>
                    </form>
                  </div>

                </div>

            } </div>
          {/* <button className='bg-project_bg_image_color rounded text-white p-2 mt-4' onClick={() => navigate("/my-donations")}>{t(`Go To Home`)} </button> */}
        </div>
      </div>

      <Aboutfootertoparea2 />

    </div>
  )
}

export default PaymentSuccess