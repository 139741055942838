import React from 'react';
import { useTranslation } from 'react-i18next';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import pdf1 from "../../../src/assets/PG-doc1.pdf";
import pdf2 from "../../../src/assets/PG-doc2.pdf";
import pdf3 from "../../../src/assets/PG-doc3.pdf";
import pdf4 from "../../../src/assets/PG-doc4.pdf";
import pdf5 from "../../../src/assets/PG-doc5.pdf";
import pdf6 from "../../../src/assets/PG-doc6.pdf";
// Define an array of achievement data
const achievementData = [
  {
    icon: <i aria-hidden="true" className="fas fa-user-graduate"></i>,
    titleKey: "Education and training field",
    descKey: "Education and training field description",
    value: "83",
    pdf:pdf1
  },
  {
    icon: <i aria-hidden="true" className="fas fa-hand-holding-heart"></i>,
    titleKey: "The social and awareness field",
    descKey: "The social and awareness field description",
    value: "83",
    pdf:pdf2
  },
  {
    icon: <i aria-hidden="true" className="fas fa-mosque "></i>,
    titleKey: "Projects of the Haram and visitors to the city",
    descKey: "Projects of the Haram and visitors to the city description",
    value: "79",
    pdf:pdf3
  },
  {
    icon: <i aria-hidden="true" className="fas fa-book "></i>,
    titleKey: "Forum Guide",
    descKey: "Forum Guide description",
    value: "22",
    pdf:pdf4
  },
  {
    icon: <i aria-hidden="true" className="fas fa-stethoscope "></i>,
    titleKey: "Health and environmental field",
    descKey: "Health and environmental field description",
    value: "12",
    pdf:pdf5

  },
  {
    icon: <i aria-hidden="true" className="fas fa-chart-line "></i>,
    titleKey: "Development field",
    descKey: "Development field description",
    value: "1,120,000",
    pdf:pdf6
  },
];

const ProjectGuide2Card = ({ page, setpage }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="w-full py-12 lg:py-32 bg-[#eeece1] rounded-lg overflow-hidden" style={{ zIndex: "999" }}>
      <div className="w-11/12 mx-auto flex flex-col items-center gap-10 lg:gap-16">
        <h3
          className={`text-[#0A4E68] text-4xl text-center font-medium ${
            i18n.language === "ar" ? "cairo" : "manrope"
          }`}
          style={{ zIndex: "100" }}
        >
          {t(`${page}.SECOND EDITION OF ALMOULTQA GUIDE`)}
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Use map to dynamically generate AchievementCard components */}
          {achievementData.map((achievement, index) => (
            <AchievementCard
              key={index}
              icon={achievement.icon}
              title={t(`${page}.${achievement.titleKey}`)}
              desc={t(`${page}.${achievement.descKey}`)}
              value={achievement.value}
              pdf={achievement?.pdf}
              t={t}
              page={page}
            />
          ))}
        </div>
        <div
          onClick={() => setpage(page === "ProjectGuide2" ? "ProjectGuide" : "ProjectGuide2")}
          className="justify-center px-4 py-2 text-lg font-semibold text-white bg-sky-900 rounded-[40px] cursor-pointer"
        >
          {page === "ProjectGuide" ? t("SECOND PAGE") : t("FIRST PAGE")}
        </div>
      </div>
    </div>
  );
};

const AchievementCard = ({ icon, title, desc, value, t, page, pdf }) => (
    <div className="flex ProjectGuide flex-col justify-between items-center p-2.5 text-lg font-semibold bg-white rounded-3xl max-w-[499px] text-sky-900 text-opacity-60">
    <div className="p-4">
    {icon}
    </div>
    <div className="self-stretch mt-7 text-2xl leading-7 text-center text-sky-900 max-md:max-w-full">
      {title}
    </div>
    <div className="self-stretch mt-4 leading-6 text-center max-md:max-w-full">
      {desc}
    </div>
    <div className="mt-4 w-full ">
      <div className="h-64 overflow-hidden">
        <Worker workerUrl={`${process.env.PUBLIC_URL}/pdf.worker.min.js`}>
          <Viewer fileUrl={pdf} />
        </Worker>
      </div>
      <div className="mt-12 text-base leading-6 text-center underline max-md:mt-10">
      <a href={pdf} target="_blank" rel="noopener noreferrer">
        {t(`${page}.Quick browse`)}
      </a>
      </div>
    </div>
  </div>
);


export default ProjectGuide2Card;
