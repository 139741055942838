import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { onSnapshot, query, collection, where, and } from "@firebase/firestore";
import { isAuth } from "../../HelperFunctions";
import toast from "react-hot-toast";
import { callAxios } from "../../axios";
import ChatContent from "./ChatContent";
import { useTranslation } from "react-i18next";
import Header from "../UI/Header";
import ChatSidebar from "./ChatSidebar";

const Chat = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedChat, setSelectedChat] = useState({});
  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation();
  const getUserInfo = JSON.parse(localStorage.getItem("userDetails"));
  const userId = getUserInfo._id;

  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768); // Adjust breakpoint as needed

  const handleResize = () => {
    setIsLargeScreen(window.innerWidth >= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getUsers = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", `user/getCharityUserChat?search=${search}`);
      setUsers(data?.data);
      setNewUsers(data?.data);
      setLoading(false);
    } catch (error) {
      toast.error(t(error?.response?.data?.message) || t("Something went wrong"));
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [search]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(db, "users"),
        and(where("newMessage", "==", true), where("receiver", "==", userId))
      ),
      (snapshot) => {
        const newUsers = snapshot.docs.map(doc => doc.data());
        const messageIds = new Set(newUsers.map(user => user.sender));

        const usersWithMessages = users.filter(user => messageIds.has(user._id))
          .map(user => ({ ...user, newMessage: true }));

        const usersWithoutMessages = users.filter(user => !messageIds.has(user._id))
          .map(user => ({ ...user, newMessage: false }));

        setNewUsers([...usersWithMessages, ...usersWithoutMessages]);
      }
    );

    return () => unsubscribe();
  }, [users]);

  return (
    <>
      <section className="bg-[#0A4D69] flex flex-col relative">
        <Header />
      </section>
      <div className="container mx-auto mt-4 shadow-lg rounded-lg h-[85vh]">
        <div className="flex flex-row  bg-white h-[85vh]">
          <ChatSidebar
            search={search}
            setSearch={setSearch}
            users={newUsers}
            setSelectedChat={setSelectedChat}
            selectedChat={selectedChat} // Pass the selected chat
            alwaysOpen={isLargeScreen} // Pass the prop based on screen size
            open={open} setOpen={setOpen}
          />
          <ChatContent selectedChat={selectedChat} setNewUsers={setNewUsers} open={open} setOpen={setOpen} alwaysOpen={isLargeScreen}/>
        </div>
      </div>
    </>
  );
};

export default Chat;
