import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../../Nav_Form/Navbar';
import { useFormik } from 'formik';
import { signUpByOrgInitialValue } from '../../../Validations/Organization/initialValues';
import { signupValidationForOrg } from '../../../Validations/Organization';
import toast from 'react-hot-toast';
import { signupOrgApi } from '../../../Api';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import Header from '../../UI/Header';
import Footer from '../../Footer/Footer';

function SignUp() {
  const [rememberMe, setRememberMe] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      role: '',
    },
    validationSchema: yup.object({
      email: yup.string().email('Invalid email').required('Email Is Required'),
      password: yup.string().min(6, 'Password must be at least 6 characters').required('Password'),
      role: yup.string().oneOf(['CHARITY', 'DONOR', 'CONSULTANT', 'ENDOWERS'], 'Invalid role').required('Role is required'),
    }),
    onSubmit: async (values) => {
      // let role = pathname === '/register' ? 'DONOR' : 'CHARITY';
      const { email, password, role} = values;
      try {
        const res = await signupOrgApi({ email, password, role });
        if (res.status === 200 || res.status === 201) {
          toast.success(t('Please check your email to verify your account'), { id: 'verificationToast' });
          navigate("/login", { replace: true });
        }
      } catch (error) {
        // Display the error message properly
        const errorMessage = error?.response?.data?.message || t('An unexpected error occurred');
        toast.error(errorMessage, { id: 'err501' });
      }
    }
  });

  return (
    <div className={`relative ${i18n.language === 'ar' ? 'cairo' : 'manrope'}`} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
      <section className="bg-[#0A4D69] h-[11vh] flex flex-col z-40 relative">
        <Header />
      </section>

      <div className='flex justify-center items-center h-screen '>
        <form onSubmit={formik.handleSubmit}>
          <div className='flex flex-col border sm:w-[450px] md:w-[500px] lg:w-[500px] h-[650px] shadow-lg rounded-md items-center px-8 py-20'>
            <div className='flex items-center mb-4'>
              <Link><img src='/login_image/login.svg' alt='' className='mb-4 mr-2' /> </Link>
              <span className='text-xl text-green-900'>{t('Login1.Al-moultqa')}</span>
            </div>

            <h1 className={`text-xl font-normal mb-4 my-3 ${i18n.language === 'ar' ? 'cairo' : 'manrope'}`}>{t('Welcome to Al-moultqa!')} 👋</h1>
            {pathname === "/register-by-org" ? (<p className='mb-4'>{t(`Login1.Please sign in to your account`)}</p>) : (<p className='mb-4'>{t('Please sign up to Al-moultqa')}</p>)}

            <div className='flex flex-col mb-4 w-full'>
            <select
                {...formik.getFieldProps('role')}
                className='border border-gray-300 rounded-md p-2 mb-2 w-full'
              >
                <option value="" disabled>{t('Select your role')}</option>
                <option value="CHARITY">{t('Charity')}</option>
                <option value="DONOR">{t('Donor')}</option>
                <option value="CONSULTANT">{t('Consultant')}</option>
                <option value="ENDOWERS">{t('Endowers')}</option>
              </select>
              {formik.touched.role && formik.errors.role ? (
                <p className='text-red-500'>{t(formik.errors.role)}</p>
              ) : null}
              <input
                type='email'
                placeholder={t('Please enter your email')}
                {...formik.getFieldProps('email')}
                className='border border-gray-300 rounded-md p-2 mb-2 w-full'
              />
              {formik.touched.email && formik.errors.email ? (
                <p className='text-red-500'>{t(formik.errors.email)}</p>
              ) : null}
              <input
                name='password'
                type='password'
                placeholder={t('Please enter your password')}
                {...formik.getFieldProps('password')}
                className='border border-gray-300 rounded-md p-2 mb-2 w-full'
              />
              {formik.touched.password && formik.errors.password ? (
                <p className='text-red-500'>{t(formik.errors.password)}</p>
              ) : null}
              <div className='flex items-center mb-2'>
                <input
                  type='checkbox'
                  checked={rememberMe}
                  onChange={e => setRememberMe(e.target.checked)}
                  className='mr-2'
                />
                <label htmlFor='remember-me'>{t('Remember Me')}</label>
              </div>
              <div className='flex items-center mb-4'>
                <input
                  type='checkbox'
                  checked={termsAccepted}
                  onChange={e => setTermsAccepted(e.target.checked)}
                  className='mr-2'
                />
                <label htmlFor='terms'>{t('I accept')} <button
                  type='button'
                  className='text-blue-500 underline ml-1'
                  onClick={() => window.open('/terms_condition.pdf', '_blank')}
                >
                  {t('Terms and Conditions')}
                </button></label>
              </div>
            </div>
            <button
              className={`bg-green-500 text-white px-4 py-2 rounded-md w-full ${!termsAccepted ? 'opacity-50 cursor-not-allowed' : ''}`}
              type='submit'
              disabled={!termsAccepted}
            >
              {t("Sign Up")}
            </button>
          </div>
        </form>
      </div>
      <img
        src='/login_image/login_image2.svg'
        alt=''
        className='absolute top-6 right-[30px] mt-8'
      />

      <Footer />
    </div>
  );
}

export default SignUp;
