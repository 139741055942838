import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import 'tailwindcss/tailwind.css';
import Loader from "react-js-loader";

import { callDomainForOrg, getAllConsultantApi } from '../../Api';
import { useTranslation } from 'react-i18next';

const AvailabilityCard = () => {
    const navigate = useNavigate()
    let [Loading, setLoading] = useState(false)
    const [domain, setDomain] = useState([])

    //pagination

    const [filter, setFilter] = useState("");
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [nextPage, setNextPage] = useState(1)

    const [consultant, setConsultant] = useState([])
    console.log(consultant)


    const { t } = useTranslation()

    const project_image_color = "rgb(82, 168, 181)";
    const project_bg_image_color = "rgb(8, 131, 149)";


    async function getAllConsultant() {
        let { data } = await getAllConsultantApi(filter, nextPage, limit)
        // console.log(data)
        setLimit(data?.limit)
        setPage(data?.page)
        setTotal(data?.total)
        setTotalPages(data?.totalPages)
        setConsultant(data?.data)
        setLoading(true)
    }

    async function getAllDomain() {
        let { data } = await callDomainForOrg()
        // console.log(data)
        setDomain(data?.domains)

    }


    let paginationButton = () => {
        let arr = []
        for (let i = 1; i <= totalPages; i++) {
            arr.push(

                <button
                    key={i}
                    onClick={() => setNextPage(i)}
                    className={`relative z-10 inline-flex items-center ${nextPage === i ? 'bg-indigo-600' : ''} px-4 py-2 text-sm font-semibold ${nextPage === i ? 'text-white ' : 'text-black'} focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                >
                    {i}
                </button>

            )

        }
        // console.log(arr)
        return arr
    }

    useEffect(() => {
        getAllConsultant()
        getAllDomain()
    }, [filter, nextPage]);

    return (
        <div className="causes_area gray-bg pt-90 pb-85">
            {Loading ? <div className="sm:mx-4 sm:mx-6 lg:mx-20">

                <div className="row">
                    <div className="col-xxl-12 text-center">
                        <div className="cause_button_wrapper mb-25">
                            <nav>
                                <div className="nav tab_buttons" id="nav-tab" role="tablist">
                                    <button

                                        className={`nav-link ${filter === '' ? 'active' : ''}`}
                                        onClick={() => setFilter("")}
                                    >
                                        {t('All')}
                                    </button>
                                    {
                                        domain && domain?.map((item, i) => <button key={`asdfa1s-${i}`} className={`nav-link ${filter === "" ? "active" : ""}`} onClick={() => setFilter(item?._id)}
                                        >
                                            {item?.name}</button>
                                        )

                                    }

                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="tab-content mb-5" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                        <div className="flex gap-4 flex-wrap">
                {consultant?.length ? consultant.map((user, index) => (
                    <div key={index} onClick={() => navigate(`/consultant-detail/${user?._id}`, { state: { consultant: user } })} className="bg-white rounded-lg shadow-lg cursor-pointer p-6 transform transition duration-500 hover:scale-105 hover:shadow-2xl" style={{ backgroundColor: project_bg_image_color }}>
                        <div className=" mb-4">
                            <div className="bg-gray-100 rounded-full h-12 w-12 my-2 flex items-center justify-center mr-4" style={{ backgroundColor: project_image_color }}>
                                <span className="text-white text-lg font-bold">{user?.name?.charAt(0).toUpperCase()}</span>
                            </div>
                            <div>
                                <h2 className="text-xl font-bold text-black">{user.name}</h2>
                                <p className="text-black">{user.email}</p>
                            </div>
                        </div>

                    </div>
                ))
                : <div className='text-center my-4'>{t('No data available')}</div>}
                </div>
                </div>
                </div>

                <div className='bg-white   '>

                    <div className="flex items-center justify-between  border-gray-200 bg-white px-4 py-3 sm:px-6">
                        <div className="flex flex-1 justify-between sm:hidden">
                            <a
                                href="#"
                                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                onClick={() => nextPage !== 1 && setNextPage((prev) => prev - 1)}
                            >
                                {t('Previous')}
                            </a>
                            <a
                                href="#"
                                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                disabled={nextPage == totalPages ? true : false}
                                // disabled={true}
                                onClick={() => nextPage !== totalPages && setNextPage((prev) => prev + 1)}

                            >
                                {t('Next')}
                            </a>
                        </div>
                        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                            <div>
                                <p className="text-sm text-gray-700">
                                    {t(`Showing`)} <span className="font-medium">{limit * (page - 1) + 1}</span> {t('to')} <span className="font-medium">{limit * (page - 1) + consultant?.length}</span> {t('of')}{' '}
                                    <span className="font-medium">{total}</span> {t(`results`)}
                                </p>
                            </div>
                            <div>
                                <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                                    <a
                                        href="#"
                                        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                        onClick={() => nextPage == 1 && setNextPage((prev) => prev - 1)}

                                    >
                                        <span className="sr-only">{t('Previous')}</span>
                                        <span className="h-5 w-5" aria-hidden="true" >{"<"}</span>
                                    </a>

                                    {paginationButton().map(item => item)
                                    }



                                    <a
                                        href="#"
                                        disabled={nextPage == totalPages ? true : false}
                                        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                        onClick={() => nextPage !== totalPages && setNextPage((prev) => prev + 1)}

                                    >
                                        <span className="sr-only">{t('Next')}</span>
                                        <span className="h-5 w-5" aria-hidden="true" >{">"}</span>
                                    </a>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <span> <Loader type="spinner-circle" bgColor={'black'} color={'black'} size={100} /></span>
            }

        </div>
    );
};

export default AvailabilityCard;
