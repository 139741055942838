import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const LoginNavbar = () => {
    const { t, i18n } = useTranslation();

    let [language, setLanguage] = useState("en");
    const navigate = useNavigate()

    useEffect(() => {
        const lang = localStorage.getItem("language");
        if(!lang){
          const newLanguage = i18n.language === "en" ? "en" :  "ar";
          localStorage.setItem("language", newLanguage);
        }
        setLanguage(lang);
      }, [localStorage.getItem("language")]);


    const toggleLanguage = () => {
        const newLanguage = i18n.language === "en" ? "ar" : "en";
        i18n.changeLanguage(newLanguage);
        localStorage.setItem("language", newLanguage);
      };

    console.log(t);
    return (
        <div
        dir={language == "ar" ? "rtl" : "ltr"}
        className="text-white flex justify-between items-center w-[86%] mx-auto pt-3 h-[80px]"
        >
            <Link to={`/`}>
                <img src="/logo.png" alt="alMoultqa Logo" width={150} height={100} />
            </Link>

            <div className="flex items-center justify-between gap-4">
                {/* <img src='/images/ear-slash.svg' className='w-[20px]' /> */}
                {/* <img src='/images/eye.svg' className='w-[20px]' /> */}
                {/* <img src='/images/textIcon.svg' className='w-[20px]' /> */}
                <select
                    onChange={toggleLanguage}
                    value={window.localStorage.getItem("language")}
                    className={`px-1 py-1 text-xs  rounded text-black cursor-pointer ${window.localStorage.getItem("language") == "ar" ? "ml-8" : ""
                        }`}
                >
                    <option value="en">English</option>
                    <option value="ar">العربية</option>
                </select>
            </div>
        </div>
    );
}

export default LoginNavbar
