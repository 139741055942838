import React, { useState } from "react";
import ChatLog from "./ChatLog";
import { useTranslation } from "react-i18next";
import { db, fb_messages } from "../../firebase";
import {
  addDoc,
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
  updateDoc,
  doc,
  and,
} from "@firebase/firestore";
import { FaSearch } from "react-icons/fa";
import { IconButton } from "@mui/material";
import { IoMdChatbubbles } from "react-icons/io";

const ChatContent = ({ selectedChat, setNewUsers, open, setOpen, alwaysOpen }) => {
  const [msg, setMsg] = useState("");
  const { t } = useTranslation();
  const getUserInfo = JSON.parse(localStorage.getItem("userDetails"));
  const userId = getUserInfo._id;

  const handleSendMsg = async (e) => {
    e.preventDefault();
    const message = msg.trim();
    if (message.length) {
      try {
        await addDoc(collection(db, fb_messages), {
          message: message,
          sender: userId,
          receiver: selectedChat?._id,
          timestamp: new Date(),
        });

        const userRef = collection(db, "users");
        const userQuery = query(
          userRef,
          and(
            where("newMessage", "==", true),
            where("receiver", "==", selectedChat?._id),
            where("sender", "==", userId)
          )
        );
        const userSnapshot = await getDocs(userQuery);

        if (userSnapshot.empty) {
          await addDoc(userRef, {
            sender: userId,
            receiver: selectedChat?._id,
            timestamp: new Date(),
            newMessage: true,
          });
        } else {
          const userDoc = userSnapshot.docs[0];
          await updateDoc(userDoc.ref, {
            newMessage: true,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    setMsg("");
  };
  const toggleDrawer = () =>{
    setOpen(prev => !prev)
  }

  return (
    <>
      {selectedChat && (
        <div className="flex flex-col flex-1  bg-white h-[85vh]"> { !alwaysOpen && <div>
          <IconButton onClick={toggleDrawer}>
            <IoMdChatbubbles />
          </IconButton>
        </div>}
      {  selectedChat?.name ?  <div className="w-full px-5 flex flex-col justify-between h-[85vh]">
          {/* Display user name and role */}
        
          <div className="flex flex-col justify-between flex-1">
           {selectedChat?.name && <div className="mt-5 mb-2 border-t border-b border-gray-300 pt-2 pb-2">
              <h2 className="text-lg font-bold">{selectedChat.name}</h2>
              <p className="text-sm text-gray-500">{selectedChat.role}</p>
            </div>}
          <div className="flex flex-col mt-5">
            <ChatLog selectedChat={selectedChat} setNewUsers={setNewUsers} />
          </div>
          </div>
          <form
            onSubmit={handleSendMsg}
            className="py-5 flex justify-between gap-8 "
          >
            <input
              className="w-full bg-gray-300 py-2 px-3 rounded-xl"
              type="text"
              value={msg}
              onChange={(e) => setMsg(e.target.value)}
              placeholder={t('type your message here...')}
            />
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-xl"
            >
              {t('Send')}
            </button>
          </form>
        </div> : <div className="flex justify-center items-center h-[85vh]">{t('Start Conversation')}</div>}
        </div>
      )}
    </>
  );
};

export default ChatContent;
