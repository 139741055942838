import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { loginOrgApi, verifyOtpLoginApi } from "../../Api"; // Import new API
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import LoginNavbar from "../LoginPage/LoginNavbar";

export default function LoginPage() {
  const { t, i18n } = useTranslation();

  const [rememberMe, setRememberMe] = useState(false);
  const [signinType, setSigninType] = useState("organization");
  const [otpSent, setOtpSent] = useState(false); // State to track OTP form display
  const [emailForOtp, setEmailForOtp] = useState(""); // Store email for OTP verification

  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  // Formik for Login
  const formikLogin = useFormik({
    initialValues: {
      email: "",
      password: "",
      role: "CHARITY",

    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Invalid email")
        .required(`Email is a required field`),
      password: yup
        .string()
        .min(6, `Password must be at least 6 characters`)
        .required(`Password`),
    }),
    onSubmit: async (values) => {
      const { email, password, role } = values;
      // let role = pathname === "/login" ? "DONOR" : "CHARITY";

      try {
        const res = await loginOrgApi({ email, password, role });
        if (res.status === 200 || res.status === 201) {
          if (res.data.token === false) {
            toast(t(res.data.message), { icon: "⚠️", id: "1102" });
          } else {
            setEmailForOtp(email); // Store email for OTP verification
            setOtpSent(true); // Show OTP form after login success
            toast.success(t("OTP sent to your email"), { id: "otpSent" });
          }
        }
      } catch (error) {
        toast.error(t(error?.response?.data?.message), { id: "err501" });
      }
    },
  });

  // Formik for OTP Verification
  const formikOtp = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: yup.object({
      otp: yup
        .string()
        .required(`OTP is required`)
        .min(6, `OTP must be 6 digits`),
    }),
    onSubmit: async (values) => {
      const { otp } = values;
      try {
        const res = await verifyOtpLoginApi({ email: emailForOtp, otp });
        if (res.status === 200) {
          toast.success(t("OTP verified successfully"), { id: "otpSuccess" });
          // Navigate to the appropriate page based on the role
          if (res?.data?.token === false) {
            toast(t(res?.data?.message), { icon: "⚠️", id: "1102" });
          } else {
            toast.success(t(res?.data?.message), { id: "001" });
            localStorage.setItem("token", res?.data?.token);
            localStorage.setItem(
              "userDetails",
              JSON.stringify(res?.data?.user)
            );
            // console.log(location)
            if (location?.state?.donate) {
              navigate("/causes-details", {
                state: { item: location?.state?.item },
              });
              return;
            }
            if (res?.data?.user?.role == "DONOR") {
              if (!res?.data?.user.isProfileCreated) {
                navigate("/donor", { token: res?.data?.token });
              } else {
                navigate("/my-bookings", { replace: true });
              }
            } else if(res?.data?.user?.role == "CHARITY") {
              if (!res?.data?.user.isProfileCreated) {
                navigate("/charity", { token: res?.data?.token });
              } else {
                // navigate("/my-analytics", { replace: true });
                navigate(`/my-analytics`, { replace: true });
              }
            } else if(res?.data?.user?.role == "CONSULTANT") {
              if (!res?.data?.user.isProfileCreated) {
                navigate("/consultant-profile", { token: res?.data?.token });
              } else {
                // navigate("/my-analytics", { replace: true });
                navigate(`/my-analytics`, { replace: true });
              }
            }
            else{
              navigate("/", { token: res?.data?.token });
            }
          }
        }
      } catch (error) {
        toast.error(t(error?.response?.data?.message), { id: "otpError" });
      }
    },
  });

  return (
    <section
      className={`w-full h-[100vh]  bg-cover ${
        i18n.language === "ar" ? "cairo" : "manrope"
      } `}
      style={{
        backgroundImage: "url('/images/loginImg.png')",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <section className="h-[11vh] flex flex-col z-40 relative">
        <LoginNavbar />
      </section>

      <div className="w-[100%] px-4 sm:px-24 mt-16">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-[500px] min-h-[600px]">
          {!otpSent ? (
            <>
              {/* Login Form */}
              <form className="text-[#084F6799]" onSubmit={formikLogin.handleSubmit}>
                {/* Sign In As Section */}
                <p className="text-[#084F6799] mb-4">{t("Login1.Login As")}</p>
                <div className="flex items-center gap-4 mb-4">
    <label className="inline-flex items-center">
      <input
        type="radio"
        name="loginAs"
        value="DONOR"
        className="form-radio"
        onChange={() => formikLogin.setFieldValue("role", "DONOR")}
      />
      <span className="mx-2">{t("Login1.Donor")}</span>
    </label>
    <label className="inline-flex items-center">
      <input
        type="radio"
        name="loginAs"
        value="CHARITY"
        className="form-radio"
        defaultChecked
        onChange={() => formikLogin.setFieldValue("role", "CHARITY")}
      />
      <span className="mx-2">{t("Login1.Organization")}</span>
    </label>
    <label className="inline-flex items-center">
      <input
        type="radio"
        name="loginAs"
        value="CONSULTANT"
        className="form-radio"
        onChange={() => formikLogin.setFieldValue("role", "CONSULTANT")}
      />
      <span className="mx-2">{t("Consultant")}</span>
    </label>
    <label className="inline-flex items-center">
      <input
        type="radio"
        name="loginAs"
        value="ENDOWERS"
        className="form-radio"
        onChange={() => formikLogin.setFieldValue("role", "ENDOWERS")}
      />
      <span className="mx-2">{t("ENDOWERS")}</span>
    </label>
  </div>

                {/* Email and Password Inputs */}
                <div className="mb-4 space-y-1">
                  <label htmlFor="email">{t("Login1.Enter Email")}</label>
                  <input
                    type="email"
                    {...formikLogin.getFieldProps("email")}
                    placeholder={t("Enter the email")}
                    className="w-full p-3 border border-gray-300 rounded-lg"
                  />
                  {formikLogin.touched.email && formikLogin.errors.email && (
                    <p className="text-red-500">{t(formikLogin.errors.email)}</p>
                  )}
                </div>

                <div className="mb-4 space-y-1">
                  <label htmlFor="password">{t("Login1.Enter Password")}</label>
                  <input
                    type="password"
                    {...formikLogin.getFieldProps("password")}
                    placeholder={t("Enter the password")}
                    className="w-full p-3 border border-gray-300 rounded-lg"
                  />
                  {formikLogin.touched.password && formikLogin.errors.password && (
                    <p className="text-red-500">{t(formikLogin.errors.password)}</p>
                  )}
                </div>
            <div className="flex items-center justify-between mb-6">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <span className="mx-2 text-[#0A4D69]">
                  {t("Login1.Remember Me")}
                </span>
              </label>
              <span
                href="#"
                className="text-sm text-[#0A4D69]"
                onClick={() => navigate("/forget-password")}
              >
                {t("Login1.Forget Password?")}
              </span>
            </div>
            <button
              type="submit"
              className="w-full p-3 bg-[#0A4D69] text-white rounded-lg"
            >
                  {t("Login1.Login")}
                </button>
              </form>
              <p className="text-center mt-4 text-[#084F6799]">
            {t("Login1.Don't have an account?")}{" "}
            {formikLogin?.values?.role === "CHARITY" ? (
              <span
                onClick={() => navigate("/register-by-org")}
                className="underline cursor-pointer"
              >
                {t("Login1.Create an account")}
              </span>
            ) : (
              <span
                onClick={() => navigate("/register")}
                className="underline cursor-pointer"
              >
                {t("Login1.Create an account")}
              </span>
            )}
          </p>
            </>
          ) : (
            <>
              {/* OTP Verification Form */}
              <form className="text-[#084F6799]" onSubmit={formikOtp.handleSubmit}>
                <div className="mb-4 space-y-1">
                  <label htmlFor="otp">{t("Enter OTP")}</label>
                  <input
                    type="text"
                    {...formikOtp.getFieldProps("otp")}
                    placeholder={t("Enter the 6-digit OTP")}
                    className="w-full p-3 border border-gray-300 rounded-lg"
                  />
                  {formikOtp.touched.otp && formikOtp.errors.otp && (
                    <p className="text-red-500">{t(formikOtp.errors.otp)}</p>
                  )}
                </div>

                {/* Submit Button */}
                <button type="submit" className="w-full p-3 bg-[#0A4D69] text-white rounded-lg">
                  {t("Verify OTP")}
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </section>
  );
}
