import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from "moment-hijri";
import { IoMdDocument } from "react-icons/io";
import { callDomainForOrg } from '../../Api';
import ViewNotes from '../../pages/ViewNotes';
import { BsThreeDotsVertical } from "react-icons/bs";
import CommentDetailModal from './CommentDetailModal';


const DemoProjectInfo = ({ projectDetail, name, projectComments }) => {
    console.log("projectDetail in info", projectDetail)
    console.log("projectComments in info ", projectComments)
    console.log("projectComments array ", projectComments)


    const checkKeyInArray = (array, key) => {
        return array.some(item => key in item);
    };


    const [isModalOpen, setIsModalOpen] = useState(false)

    const [selectedInput , setSelectedInput] = useState('')


    const handleDotClick = (selectedInput)=>{
        // debugger;
        setSelectedInput(selectedInput)
        setIsModalOpen(true)
    }


    const handleCloseModal = () => {
        setIsModalOpen(false); // Close the modal
    };

    const handleOpenModal = () => {
        setIsModalOpen(true); // Close the modal
    };

    const { t } = useTranslation()
    const openLink = (link) => {
        // console.log(link)
        window.open(link, '_blank');
    };

    const [domain, setDomain] = useState([])



    async function callDomainType(params) {
        try {

            const data = await callDomainForOrg()
            setDomain(data?.data?.domains)
        } catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        callDomainType()
    }, [])

    console.log("projectDetail", projectDetail);

    return (
      <div>
        <div className=" grid-cols-1 gap-4 mt-10 grid lg:w-[65%] shadow-md px-5 pb-12 mx-auto rounded-md">
          {/* first section */}
          <div className="w-full pt-12 ">
            <h1 className="text-2xl   text-blue-900 mb-3 border-gray-300 border-b-2">
              {t("General Information")}
            </h1>
            <div className="pb-3 w-full">
              <div className="flex w-full gap-10 ">
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black p-1">{t(`Project Domain`)}: </div>
                  <div className="flex gap-2 items-center">
                    <div className="w-[90%] p-1 rounded-md border">
                      {domain.filter(
                        (item) => item._id === projectDetail?.domain._id
                      )[0]?.name || t("No data available")}
                    </div>

                    {checkKeyInArray(projectComments, "domain") ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("domain");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black p-1">{t(`Project Name`)}: </div>
                  <div className="flex items-center gap-2">
                    <div className="w-[90%] p-1 rounded-md border">
                      {projectDetail?.projectName || t("No data available")}
                    </div>
                    {checkKeyInArray(projectComments, "projectName") ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("projectName");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="flex w-full gap-10">
                <div className=" mb-4 mt-2 flex flex-col gap-1 w-[50%]">
                  <div className="text-black   ">
                    {t(`Project Justification`)}:{" "}
                  </div>
                  <div className="flex items-center gap-2">
                    {/* <div className='w-[90%] p-1 rounded-md border'>{projectDetail?.projectJustification || t('No data available')}</div> */}
                    {projectDetail?.projectJustification?.map((item) => {
                      return (
                        <div className="w-[90%] px-2 p-1 border rounded-md">
                          {item || <>{t("No data available")}</>}
                        </div>
                      );
                    })}
                    {checkKeyInArray(
                      projectComments,
                      "projectJustification"
                    ) ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("projectJustification");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black   ">{t(`Project Overview`)}: </div>
                  <div className="flex items-center gap-2">
                    <div className="w-[90%] p-1 rounded-md border">
                      {projectDetail?.projectOverview || t("No data available")}
                    </div>

                    {checkKeyInArray(projectComments, "projectOverview") ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("projectOverview");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="flex w-full gap-10">
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black   ">
                    {t(`Project Objectives`)}:{" "}
                  </div>
                  <div className="flex items-center gap-2">
                    {projectDetail?.projectObjectives?.map((item) => {
                      return (
                        <div className="w-[90%] px-2 p-1 border rounded-md">
                          {item || <>{t("No data available")}</>}
                        </div>
                      );
                    })}
                    {checkKeyInArray(projectComments, "projectObjectives") ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("projectObjectives");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black   ">
                    {t(`Targeted Audience`)}:{" "}
                  </div>
                  <div className="flex items-center gap-2">
                    <div className=" w-[90%] p-1 rounded-md border">
                      {projectDetail?.targetedAudience ||
                        t("No data available")}
                    </div>

                    {checkKeyInArray(projectComments, "targetedAudience") ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("targetedAudience");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="flex w-full gap-10">
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black   ">{t(`Region`)}: </div>
                  <div className="flex items-center gap-2">
                    {/* <div className=' w-[90%] p-1 border h-8 rounded-md'>{projectDetail?.region?.name || t('No data available')}</div> */}

                    {projectDetail?.region?.map((item) => {
                      return (
                        <div className="w-[90%] px-2 p-1 border rounded-md">
                          {item?.name || <>{t("No data available")}</>}
                        </div>
                      );
                    })}
                    {checkKeyInArray(projectComments, "region") ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("region");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className=" w-[50%] ">
                  <div className="mb-4">
                    <div className="text-black font-semibold">
                      {t(`Project Picture`)}:
                    </div>
                  </div>
                  {projectDetail?.projectImage ? (
                    <div
                      className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4"
                      onClick={() => openLink(projectDetail?.projectImage)}
                    >
                      <span className="mr-2">
                        {" "}
                        <IoMdDocument />
                      </span>{" "}
                      <button>{t(`View document`)}</button>
                      {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                                    </svg> */}
                    </div>
                  ) : (
                    <div
                      aria-disabled
                      className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4"
                    >
                      <span className="mr-2">
                        {" "}
                        <IoMdDocument />
                      </span>{" "}
                      <button>{t(`No document`)}</button>
                      {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                                </svg> */}
                    </div>
                  )}
                </div>
              </div>

              <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                <div className="text-black   ">{t(`City`)}: </div>
                <div className="flex items-center gap-2">
                  {/* <div className=' w-[90%] p-1 border h-8 rounded-md'>{projectDetail?.region?.name || t('No data available')}</div> */}

                  {projectDetail?.city?.map((item) => {
                    return (
                      <div className="w-[90%] px-2 p-1 border rounded-md">
                        {item?.cityName || <>{t("No data available")}</>}
                      </div>
                    );
                  })}
                  {checkKeyInArray(projectComments, "city") ? (
                    <div
                      className="p-2 rounded-full hover:bg-gray-300"
                      onClick={() => {
                        handleDotClick("city");
                      }}
                    >
                      <BsThreeDotsVertical className="cursor-pointer" />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* second section */}
          <div className="w-full">
            <h1 className="text-2xl   text-blue-900 mb-3 border-gray-300 border-b-2">
              {t("Detailed Information")}
            </h1>
            <div className="pb-3 w-full">
              <div className="flex w-full gap-10">
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black   ">
                    {t(`Expected Outcomes`)}:{" "}
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-[90%] p-1 rounded-md border">
                      {projectDetail?.expectedOutcomes ||
                        t("No data available")}
                    </div>
                    {checkKeyInArray(projectComments, "expectedOutcomes") ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("expectedOutcomes");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black   ">{t(`Expected Impact`)}: </div>
                  <div className="flex items-center gap-2">
                    <div className=" w-[90%] p-1 rounded-md border">
                      {projectDetail?.expectedImpact || t("No data available")}
                    </div>

                    {checkKeyInArray(projectComments, "expectedImpact") ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("expectedImpact");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="flex w-full gap-10">
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black   ">
                    {t(`Creative And Innovative Aspect`)}:{" "}
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-[90%] p-1 rounded-md border">
                      {projectDetail?.creativeInnovativeAspect ||
                        t("No data available")}
                    </div>

                    {checkKeyInArray(
                      projectComments,
                      "creativeInnovativeAspect"
                    ) ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("creativeInnovativeAspect");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black   ">
                    {t(`Team Members Partnerships`)}:{" "}
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-[90%] p-1 rounded-md border">
                      {projectDetail?.teamMembersPartnerships ||
                        t("No data available")}
                    </div>
                    {checkKeyInArray(
                      projectComments,
                      "teamMembersPartnerships"
                    ) ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("teamMembersPartnerships");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="flex w-full gap-10">
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black   ">
                    {t(`Partnerships Type`)}:{" "}
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-[90%] p-1 rounded-md border">
                      {projectDetail?.partnershipstype ||
                        t("No data available")}
                    </div>
                    {checkKeyInArray(projectComments, "partnershipstype") ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("partnershipstype");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black   ">
                    {t(`Percentage That Each Partner Covers`)}:{" "}
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-[90%] p-1 rounded-md border">
                      {projectDetail?.teamMembersPercentageOfEachPartner ||
                        t("No data available")}
                    </div>

                    {checkKeyInArray(
                      projectComments,
                      "teamMembersPercentageOfEachPartner"
                    ) ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("teamMembersPercentageOfEachPartner");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="flex w-full gap-10 ">
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black   ">
                    {t(`Performance Measurement`)}:{" "}
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-[90%] p-1 rounded-md border">
                      {projectDetail?.teamMembersPerformanceMeasurement ||
                        t("No data available")}
                    </div>
                    {checkKeyInArray(
                      projectComments,
                      "teamMembersPerformanceMeasurement"
                    ) ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("teamMembersPerformanceMeasurement");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black   ">{t(`Start Date`)}: </div>
                  <div className="flex items-center gap-2">
                    <div className="w-[90%] p-1 rounded-md border">
                      {moment(projectDetail?.startDate).format("iYYYY-iM-iD") ||
                        t("No data available")}
                    </div>
                    {checkKeyInArray(projectComments, "startDate") ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("startDate");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="flex w-full gap-10">
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black   ">{t("End Date")}: </div>
                  <div className="flex items-center gap-2">
                    <div className="w-[90%] p-1 rounded-md border">
                      {moment(projectDetail?.closeDate).format("iYYYY-iM-iD") ||
                        t("No data available")}
                    </div>
                    {checkKeyInArray(projectComments, "closeDate") ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("closeDate");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black   ">
                    {t(`Project Implementation`)}:{" "}
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-[90%] p-1 rounded-md border">
                      {projectDetail?.teamMembersProjectImplementation
                        ? t("Yes")
                        : t("No") || t("No data available")}
                    </div>
                    {checkKeyInArray(
                      projectComments,
                      "teamMembersProjectImplementation"
                    ) ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("teamMembersProjectImplementation");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="flex w-full gap-10">
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black   ">
                    {t(`Supporting The Entity Strategy`)}:{" "}
                  </div>
                  <div className="flex items-center gap-2">
                    <div
                      className={`p-1 rounded-md border ${
                        projectDetail?.teamMembersFundingSource
                          ? "w-[90%]"
                          : "w-[86%]"
                      }`}
                    >
                      {projectDetail?.teamMembersSupportingEntityStrategy ||
                        t("No data available")}
                    </div>
                    {checkKeyInArray(
                      projectComments,
                      "teamMembersSupportingEntityStrategy"
                    ) ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("teamMembersSupportingEntityStrategy");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {projectDetail?.teamMembersFundingSource ? (
                  <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                    <div className="text-black   ">
                      {t(`Funding Percentage`)}:{" "}
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="w-[90%] p-1 rounded-md border">
                        {projectDetail?.teamMembersFundingPercentage ||
                          t("No data available")}
                      </div>

                      {checkKeyInArray(
                        projectComments,
                        "teamMembersFundingPercentage"
                      ) ? (
                        <div
                          className="p-2 rounded-full hover:bg-gray-300"
                          onClick={() => {
                            handleDotClick("teamMembersFundingPercentage");
                          }}
                        >
                          <BsThreeDotsVertical className="cursor-pointer" />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {projectDetail?.teamMembersProjectImplementation ? (
                <div className="  p-4 ">
                  <div className="mb-4">
                    <div className="text-black font-semibold">
                      {t(`Old Reports`)}:
                    </div>
                  </div>
                  {projectDetail?.teamMembersLastProjectsReports ? (
                    <div
                      className="border border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4"
                      onClick={() =>
                        openLink(projectDetail?.teamMembersLastProjectsReports)
                      }
                    >
                      <span className="mr-2">
                        {" "}
                        <IoMdDocument />
                      </span>{" "}
                      <button>{t(`View document`)}</button>
                      {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                                    </svg> */}
                    </div>
                  ) : (
                    <div className=" border-green-900	border-2 rounded-md p-2 flex justify-center items-center mb-4">
                      <span className="mr-2">
                        {" "}
                        <IoMdDocument />
                      </span>{" "}
                      <button>{t(`No document`)}</button>
                      {/* <svg className="w-16 h-20 text-gray-500" fill="none" viewBox="0 0 30 30" stroke="currentColor">
                            </svg> */}
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* third section */}
          <div className="w-full">
            <h1 className="text-2xl   text-blue-900 mb-3 border-gray-300 border-b-2">
              {t("Project Team")}
            </h1>
            <div className="pb-3 w-full">
              <div className="flex w-full gap-10">
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black   ">
                    {t(`Project Manager Name`)}:{" "}
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-[90%] p-1 rounded-md border">
                      {projectDetail?.projectManagerName ||
                        t("No data available")}
                    </div>
                    {checkKeyInArray(projectComments, "projectManagerName") ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("projectManagerName");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black   ">{t(`Full Time`)}: </div>
                  <div className="flex items-center gap-2">
                    <div className="w-[90%] p-1 rounded-md border">
                      {projectDetail?.teamMembersFulltime ||
                        t("No data available")}
                    </div>
                    {checkKeyInArray(projectComments, "teamMembersFulltime") ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("teamMembersFulltime");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className=" mb-4 flex flex-col gap-1  ">
                <div className="text-black   ">{t(`Volunteer`)}: </div>
                <div className="flex items-center gap-2">
                  <div className="w-[43%] p-1 rounded-md border">
                    {projectDetail?.teamMembersVolunteer ||
                      t("No data available")}
                  </div>
                  {checkKeyInArray(projectComments, "teamMembersVolunteer") ? (
                    <div
                      className="p-2 rounded-full hover:bg-gray-300"
                      onClick={() => {
                        handleDotClick("teamMembersVolunteer");
                      }}
                    >
                      <BsThreeDotsVertical className="cursor-pointer" />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* fourth section */}
          <div className="w-full">
            <h1 className="text-2xl   text-blue-900 mb-3 border-gray-300 border-b-2">
              {t("Detailed Project Budget")}
            </h1>

            <div className="pb-3 w-full">
              <div className="flex w-full gap-10">
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                {projectDetail?.financialDetails?.map((item) => (
  <div className="flex flex-col md:flex-row justify-between mb-4">
    <div className="md:w-[30%]">
      <div>{t("Item")} </div>
      <div className="flex items-center gap-2">
        <div className="w-[90%] p-1 rounded-md border">
          {item?.item || t("No data available")}{" "}
        </div>
        {checkKeyInArray(projectComments, "item") && (
          <div
            className="p-2 rounded-full hover:bg-gray-300 cursor-pointer"
            onClick={() => handleDotClick("item")}
          >
            <BsThreeDotsVertical />
          </div>
        )}
      </div>
    </div>
    <div className="md:w-[30%]">
      <div>{t("Quantity")} </div>
      <div className="flex items-center gap-2">
        <div className="w-[90%] p-1 rounded-md border">
          {item?.quantity || t("No data available")}
        </div>
        {checkKeyInArray(projectComments, "quantity") && (
          <div
            className="p-2 rounded-full hover:bg-gray-300 cursor-pointer"
            onClick={() => handleDotClick("quantity")}
          >
            <BsThreeDotsVertical />
          </div>
        )}
      </div>
    </div>
    <div className="md:w-[30%]">
      <div>{t("Unit Price")} </div>
      <div className="flex items-center gap-2">
        <div className="w-[90%] p-1 rounded-md border">
          {item?.price || t("No data available")}
        </div>
        {checkKeyInArray(projectComments, "price") && (
          <div
            className="p-2 rounded-full hover:bg-gray-300 cursor-pointer"
            onClick={() => handleDotClick("price")}
          >
            <BsThreeDotsVertical />
          </div>
        )}
      </div>
    </div>
  </div>
))}

                </div>

                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                {projectDetail?.financialDetails?.map((item) => (
  <div className="flex flex-col md:flex-row justify-between mb-4">
    <div className="md:w-[30%]">
      <div>{t("Before Vat")} </div>
      <div className="flex items-center gap-2">
        <div className="w-[90%] p-1 rounded-md border">
          {item?.beforeVat || t("No data available")}{" "}
        </div>
        {checkKeyInArray(projectComments, "beforeVat") && (
          <div
            className="p-2 rounded-full hover:bg-gray-300 cursor-pointer"
            onClick={() => handleDotClick("beforeVat")}
          >
            <BsThreeDotsVertical />
          </div>
        )}
      </div>
    </div>
    <div className="md:w-[30%]">
      <div>{t("Vat")} </div>
      <div className="flex items-center gap-2">
        <div className="w-[90%] p-1 rounded-md border">
          {item?.vat || t("No data available")}
        </div>
        {checkKeyInArray(projectComments, "vat") && (
          <div
            className="p-2 rounded-full hover:bg-gray-300 cursor-pointer"
            onClick={() => handleDotClick("vat")}
          >
            <BsThreeDotsVertical />
          </div>
        )}
      </div>
    </div>
    <div className="md:w-[30%]">
      <div>{t("After Vat")} </div>
      <div className="flex items-center gap-2">
        <div className="w-[90%] p-1 rounded-md border">
          {item?.afterVat || t("No data available")}
        </div>
        {checkKeyInArray(projectComments, "afterVat") && (
          <div
            className="p-2 rounded-full hover:bg-gray-300 cursor-pointer"
            onClick={() => handleDotClick("afterVat")}
          >
            <BsThreeDotsVertical />
          </div>
        )}
      </div>
    </div>
  </div>
))}

                </div>

                {/* <div className=" mb-4 flex flex-col gap-1 w-[50%] justify-between ">
                  <div className="text-black   ">
                    {t(`Financial Details Total`)}:{" "}
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-[90%] p-1 rounded-md border">
                      {projectDetail?.financialDetailsTotal ||
                        t("No data available")}
                    </div>
                    {checkKeyInArray(
                      projectComments,
                      "financialDetailsTotal"
                    ) ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("financialDetailsTotal");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div> */}
              </div>

              <div className="mb-4 flex flex-col gap-1 w-full">

                  <div
                    className="flex flex-row justify-between mb-4 w-full"
                  >
                    <div className="w-[45%]">
                      <div>{t("Total Before Vat")}</div>
                      <div className="w-full p-1 rounded-md border">
                        {projectDetail?.totalBeforeVat || t("No data available")}
                      </div>
                    </div>
                    <div className="w-[45%]">
                      <div>{t("Total After Vat")}</div>
                      <div className="w-full p-1 rounded-md border">
                        {projectDetail?.totalAfterVat || t("No data available")}
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>

          {/* fifth section */}
          <div className="w-full">
            <h1 className="text-2xl text-blue-900 mb-3 border-gray-300 border-b-2">
              {t("Project Timeline")}
            </h1>
            <div className="pb-3 w-full">
              <div className="flex gap-10 w-full">
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black   ">{t(`Activities`)}: </div>
                  <div className="flex items-center gap-2">
                    <div className="w-[90%] p-1 rounded-md border">
                      {projectDetail?.plansActivity || t("No data available")}
                    </div>
                    {checkKeyInArray(projectComments, "plansActivity") ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("plansActivity");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className=" mb-4 flex flex-col gap-1 w-[50%] ">
                  <div className="text-black   ">{t(`Timeline`)}: </div>
                  <div className="flex items-center gap-2">
                    <div className=" w-[90%] p-1 rounded-md border">
                      {projectDetail?.plansTimeline || t("No data available")}
                    </div>
                    {checkKeyInArray(projectComments, "plansTimeline") ? (
                      <div
                        className="p-2 rounded-full hover:bg-gray-300"
                        onClick={() => {
                          handleDotClick("plansTimeline");
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className=" mb-4 flex flex-col gap-1 ">
                <div className="text-black   ">{t(`Performance`)}: </div>
                <div className="flex items-center gap-2">
                  <div className="w-[43%] p-1 rounded-md border">
                    {projectDetail?.plansPerformance || t("No data available")}
                  </div>
                  {checkKeyInArray(projectComments, "plansPerformance") ? (
                    <div
                      className="p-2 rounded-full hover:bg-gray-300"
                      onClick={() => {
                        handleDotClick("plansPerformance");
                      }}
                    >
                      <BsThreeDotsVertical className="cursor-pointer" />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isModalOpen && (
          <CommentDetailModal
            onClose={handleCloseModal}
            projectComments={projectComments}
            selectedInput={selectedInput}
          />
        )}
      </div>
    );
}

export default DemoProjectInfo