import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState, Suspense } from "react";
import Header from "./Header";
import { FaArrowRight } from "react-icons/fa6";
import axios from "axios";
import { backendUrl } from "../../axios";

// Lazy load components
const Achivements = React.lazy(() => import("../HomePage/Achivements"));
const MediaCenter = React.lazy(() => import("../MediaCenter/MediaCenter"));
const HomeImageBanner = React.lazy(() => import("../HomePage/HomeImageBanner"));
const ProjectGuide = React.lazy(() => import("../ProjectGuide/ProjectGuide"));
const SupervisiorySection = React.lazy(() => import("../HomePage/SupervisiorySection"));
const Footer = React.lazy(() => import("../Footer/Footer"));
const MatururedProjects = React.lazy(() => import("../HomePage/MaturedProjects"));

// Main SliderArea Component
export default function SliderArea() {
  const { t } = useTranslation();
  const [language, setLanguage] = useState("ar");

  useEffect(() => {
    const lang = localStorage.getItem("language");
    setLanguage(lang ? lang : "ar");
  }, []);

  const [domains, setdomains] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState("");
  const [domainsCard, setdomainsCard] = useState([]);

  const handleClick = (domain) => {
    setSelectedDomain(domain);
  };

  useEffect(() => {
    axios.get(`${backendUrl}/domain/getAllDomains`).then((res) => {
      console.log("res", res.data.domains);
      setdomains(res.data.domains);
      setSelectedDomain("");
    });
  }, []);

  useEffect(() => {
    axios
      .get(`${backendUrl}/project/getAllLiveProjects?domain=${selectedDomain}&page=&limit=`)
      .then((res) => {
        console.log("res", { res });
        setdomainsCard(res.data.data);
      });
  }, [selectedDomain]);

  const { i18n } = useTranslation();

  return (
    <div className="w-full bg-[#eeece1]">
      <div
        className="bg-cover h-[90%] max-h-[850px] rounded-b-[40px] flex flex-col py-5"
        style={{
          backgroundImage: "url('/images/newHomeBanner.svg')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Header />

        {/* Main Heading Section */}
        <div className="text-white flex flex-col gap-8 w-[80%] mx-auto my-auto">
          <div className="flex flex-col gap-4">
            <p className="font-bold text-4xl md:text-6xl lg:leading-[5.5rem] lg:max-w-[800px]">
              {t("Home1.title")}
            </p>
          </div>

          <p className="w-[100%] max-w-[500px] text-lg font-light leading-8 text-justify">
            {t("Home1.description")}
          </p>

          <Link to={"/opportunity"}>
            <button
              className="bg-[#EDC76F] text-[#013C4A] px-8 py-3 rounded-3xl w-fit font-bold flex items-center gap-2"
            >
              {t("Home1.givingOpportunities")} <span className="text-sm"><FaArrowRight /></span>
            </button>
          </Link>
        </div>
      </div>

      {/* Overview Section */}
      <div className="w-full my-12 py-8">
        <div className="w-[80%] mx-auto py-8 flex flex-col items-center gap-8 justify-between">
          <h3
            className={`text-4xl text-center font-medium text-[#0A4E68] ${
              i18n.language === "ar" ? "cairo" : "manrope"
            }`}
          >
            {t("Home1.overviewTitle")}
          </h3>

          <p className="w-[100%] md:w-[100%] max-w-[800px] text-center text-lg font-semibold leading-9 text-[#084F679C]">
            {t("Home1.overviewDescription")}
          </p>

          <Link to={"/about"}>
            <button
              className="bg-[#EDC76F] text-[#013C4A] px-8 py-3 rounded-3xl w-fit font-medium flex items-center gap-2"
            >
              {t("Home1.overviewButton")} <span className="text-sm"><FaArrowRight /></span>
            </button>
          </Link>
        </div>
      </div>

      {/* Prince Message */}
      <div className="w-full my-12">
        <div className="h-[300px] bg-[#0A4D69] text-white flex p-4 flex-col gap-4 items-center justify-center text-center w-[86%] mx-auto rounded-3xl">
          <p className="text-2xl md:text-3xl italic font-[100]">
            "{t("Home1.quote")}"
          </p>
          <p className="text-3xl md:text-4xl font-medium tracking-wide leading-10">
            {t("Home1.quoteAuthor")}
          </p>
        </div>
      </div>

      {/* Achievements - Lazy Loaded */}
      <Suspense fallback={<div>Loading Achievements...</div>}>
        <Achivements />
      </Suspense>

      {/* Latest Projects - Lazy Loaded */}
      <Suspense fallback={<div>Loading Latest Projects...</div>}>
        <MatururedProjects
          domains={domains}
          selectedDomain={selectedDomain}
          domainsCard={domainsCard}
          handleClick={handleClick}
        />
      </Suspense>

      {/* Media Center - Lazy Loaded */}
      <Suspense fallback={<div>Loading Media Center...</div>}>
        <MediaCenter />
      </Suspense>

      {/* Image Banner - Lazy Loaded */}
      <Suspense fallback={<div>Loading Image Banner...</div>}>
        <HomeImageBanner />
      </Suspense>

      {/* Project Guide - Lazy Loaded */}
      <Suspense fallback={<div>Loading Project Guide...</div>}>
        <ProjectGuide />
      </Suspense>

      {/* Supervisory Section - Lazy Loaded */}
      <Suspense fallback={<div>Loading Supervisory Section...</div>}>
        <SupervisiorySection />
      </Suspense>

      {/* Footer - Lazy Loaded */}
      <Suspense fallback={<div>Loading Footer...</div>}>
        <Footer />
      </Suspense>
    </div>
  );
}
