import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import { backendUrl } from "../../axios";
import { useTranslation } from "react-i18next";
import MuiPagination from "../shared/Pagination";
import MediaCenterItem from "./MediaCenterItem";
import SliderComponent from "./SliderComponent";

const MediaCenter = ({displayPagination}) => {
  const { t, i18n } = useTranslation();

  // const slides = [
  //   {
  //     image: "/images/largeMediaImg.jpg",
  //   },
  //   {
  //     image: "/images/largeMediaImg.jpg",
  //   },
  // ];

  // const swiperRef = React.useRef(null);
  const [slides, setSlides] = useState([]);
  const [leftSectionData, setLeftSectionData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(3); // Set limit for items per page
  const swiperRef = useRef(null);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const fetchData = (page) => {
    axios
      .get(`${backendUrl}/mediacenter/getAllMediaCenter?search=&page=${page}&limit=${limit}`)
      .then((response) => {
        const data = response.data.data;
        if(currentPage == 1){
          setSlides(data); // For Swiper
        }
        setLeftSectionData(data); // For left section
        setTotalPages(response.data.totalPages); // Update total pages
      })
      .catch((error) => {
        console.error("Error fetching media center data", error);
      });
  };

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="py-4 mb-12">
      <div className="w-[90%] mx-auto flex flex-col gap-12">
       {!displayPagination && <h2
          className={`${
            i18n.language == "ar" ? "cairo" : "manrope"
          }text-[#0A4E68] text-4xl text-center font-medium tracking-wide`}
        >
          {t("Home1.MEDIA CENTER")}
        </h2>}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* left section */}
          <div className="flex flex-col justify-between gap-4">
          {leftSectionData.map((item) => (
        <MediaCenterItem key={item._id} item={item} />
      ))}
        </div>
       {slides?.length > 0 && <SliderComponent slides={slides} />}
        </div>
        
        {/* Pagination Component */}
        {displayPagination && <MuiPagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />}
      </div>
    </div>
  );
};

export default MediaCenter;
