import React from 'react';
import { Drawer, List, ListItem, ListItemText, TextField, IconButton } from '@mui/material';
import { FaSearch } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const ChatSidebar = ({ search, setSearch, users, setSelectedChat, alwaysOpen, open, setOpen, selectedChat }) => {
  const { t, i18n} = useTranslation();
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };

  const handleUserClick = (user) => {
    setSelectedChat(user);
    setOpen(false);
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      {alwaysOpen ? (
        <div
  style={{
    width: 250,
    height: "100%",
    borderRight: i18n.language === 'ar' ? "none" : "1px solid gray",
    borderLeft: i18n.language === 'ar' ? "1px solid gray" : "none",
    padding: "10px",
  }}
>          <TextField
            placeholder={t("Search Expert")}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <FaSearch />
                </IconButton>
              ),
            }}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <List>
            {filteredUsers.map((user) => (
              <ListItem 
                button 
                key={user._id} 
                onClick={() => handleUserClick(user)} 
                style={{
                  backgroundColor: selectedChat?._id === user._id ? '#e0f7fa' : 'transparent', // Highlight color
                  borderRadius: '4px',
                }}
              >
                <ListItemText primary={user.name} />
              </ListItem>
            ))}
          </List>
        </div>
      ) : (
        <>
          <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
            <div style={{ width: 250, padding: '10px' }}>
              <TextField
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <FaSearch />
                    </IconButton>
                  ),
                }}
                fullWidth
                variant="outlined"
                margin="normal"
              />
              <List>
                {filteredUsers.map((user) => (
                  <ListItem 
                    button 
                    key={user._id} 
                    onClick={() => handleUserClick(user)} 
                    style={{
                      backgroundColor: selectedChat?._id === user._id ? '#e0f7fa' : 'transparent', // Highlight color
                      borderRadius: '4px',
                    }}
                  >
                    <ListItemText primary={user.name} />
                  </ListItem>
                ))}
              </List>
            </div>
          </Drawer>
        </>
      )}
    </>
  );
};

export default ChatSidebar;
