import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import React from "react";
import Footer from "../Footer/Footer";
import Header from "./Header";
import { callAxios } from "../../axios";
import toast from "react-hot-toast";
import { MdOutlineEmail } from "react-icons/md";
import { TfiEmail } from "react-icons/tfi";

const ContactUs = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = localStorage.getItem("language") || "en";

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      findUs : "",
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .required(<>{t(`Name is a required field`)}</>),
      email: yup
        .string()
        .email("Invalid email")
        .required(<>{t(`Email is a required field`)}</>),
      phoneNumber: yup.number().required(<>{t(`Phone Number is required`)}</>)
              .max(9999999999, <>{t('Phone number must be exactly 10 digits')}</>),
      findUs : yup.string(),

    }),
    onSubmit: async (values, {resetForm}) => {
      try {
        const { data } = await callAxios(
          "post",
          "contact/sendContact",
          values
        );
        console.log("response data", data)
        if(data.status){
          toast.success(t(data.message))
          resetForm();
        }
      } catch (error) {
        console.log(error);
        toast.error(t(error?.response?.data?.message) || t("Something went wrong"));
      }
    },
  });

  return (
    <section className="w-full bg-[#eeece1]">
      <section className="bg-[#0A4D69] h-[50vh] flex flex-col  relative">
        <Header />
      </section>
      <div className="w-full  bg-[#EEEDE5]" style={{marginTop:'-300px'}}>
        <div className="w-full md:w-[80%] mx-auto flex flex-col gap-14 relative mt-20 max-md:mt-14 max-sm:mt-6">
          <h3
            className={`${i18n.language === 'ar' ? 'cairo' : 'manrope'} text-3xl md:text-6xl text-center text-white tracking-wide z-100`}
            style={{
              zIndex: "100",
            }}
          >
            {t("Contact1.Contact")}
          </h3>
          <div
            className="w-[90%] p-4 md:p-0 mx-auto py-10 md:py-20 bg-white rounded-3xl z-100"
            style={{ marginTop: "-20px"}}
          >
            <div className="w-full md:w-[90%] mx-auto flex flex-col md:flex-row justify-between items-center gap-12">
              <div className="w-full md:w-[50%] flex flex-col gap-8 md:gap-12">
                <div className="">
                  <h1 className="text-4xl md:text-5xl font-semibold mt-4 text-[#000000]">
                    {t("Contact1.Get in ")}
                    <span className="text-[#0A4D69]">{t("Contact1.Touch")}</span>
                  </h1>
                  <p className="text-[#084F679C] mt-4">{t("Contact1.contactText")}</p>
                </div>
                <form onSubmit={formik.handleSubmit} className="text-[#084F6799] flex flex-col gap-4">
                  <div className="mb-4 space-y-1">
                    <input
                      type="text"
                      {...formik.getFieldProps("name")}
                      placeholder={t("Contact1.Name *")}
                      name="name"
                      className="w-full p-3 border border-gray-300 placeholder:text-[#084F6799]"
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <p className="text-red-500">{formik.errors.name}</p>
                    ) : null}
                  </div>
                  <div className="mb-4 space-y-1">
                    <input
                      type="email"
                      {...formik.getFieldProps("email")}
                      placeholder={t("Contact1.Email *")}
                      name="email"
                      className="w-full p-3 border border-gray-300 placeholder:text-[#084F6799]"
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <p className="text-red-500">{formik.errors.email}</p>
                    ) : null}
                  </div>
                  <div className="mb-4 space-y-1">
                    <input
                      type="number"
                      maxLength="10"
                      inputMode="numeric"
                      {...formik.getFieldProps("phoneNumber")}
                      placeholder={t("Contact1.Phone Number *")}
                      name="phoneNumber"
                      className="w-full p-3 border border-gray-300 placeholder:text-[#084F6799]"
                    />
                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                      <p className="text-red-500">{formik.errors.phoneNumber}</p>
                    ) : null}
                  </div>
                  <div className="mb-4 space-y-1">
                    <input
                        type="text"
                        {...formik.getFieldProps("findUs")}
                        placeholder={t("Contact1.Write a message *")}
                        name="findUs"
                        className="w-full p-3 border border-gray-300 placeholder:text-[#084F6799]"
                      />
                      {formik.touched.findUs && formik.errors.findUs ? (
                        <p className="text-red-500">{formik.errors.findUs}</p>
                      ) : null}
                  </div>

                  <button type="submit" className="w-full p-3 bg-[#0A4D69] font-bold uppercase text-base text-white">
                    {t("Contact1.Send")}
                  </button>
                </form>

                <div className="flex flex-col md:flex-row justify-between  gap-4 md:gap-4">
                  <div className="flex items-center gap-4">
                    <img src="/images/phone.svg" alt="img" className="w-6 h-6" />
                    <div className="flex flex-col gap-1">
                      <p className="text-[#0A4D69]">{t("Contact1.PHONE")}</p>
                      <p className="text-[#084F679C] text-sm">0539543215</p>
                    </div>
                  </div>
                  {/* <div className="flex items-center gap-4">
                    <img src="/images/phone.svg" alt="img" className="w-6 h-6" />
                    <div className="flex flex-col gap-1">
                      <p className="text-[#0A4D69]">{t("Contact1.FAX")}</p>
                      <p className="text-[#084F679C] text-sm">03 5432 1234</p>
                    </div>
                  </div> */}
                  <div className="flex items-center gap-4">
                    {/* <img src="/images/phone.svg" alt="img" className="w-6 h-6" /> */}
                    <TfiEmail width={28} height={28} size={24} color="#4599A0"/>
                    <div className="flex flex-col gap-1">
                      <p className="text-[#0A4D69]">{t("Contact1.EMAIL")}</p>
                      <p className="text-[#084F679C] text-sm">info@almoultqa.com</p>
                    </div>
                  </div>
                </div>
              </div>              
              <div className="">
                <a href="https://www.google.com/maps/place/%D9%85%D8%B1%D9%83%D8%B2+%D8%B7%D8%A7%D8%A8%D8%A9+%D9%84%D9%84%D8%AF%D8%B1%D8%A7%D8%B3%D8%A7%D8%AA+%D9%88%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%B4%D8%A7%D8%B1%D8%A7%D8%AA+%D8%A7%D9%84%D9%88%D9%82%D9%81%D9%8A%D8%A9+%D9%88%D8%A7%D9%84%D9%88%D8%B5%D8%A7%D9%8A%D8%A7%E2%80%AD/@24.4274277,39.6368609,13.73z/data=!4m6!3m5!1s0x15bd95635962c53f:0x8b682e7478f183ca!8m2!3d24.4314536!4d39.6322537!16s%2Fg%2F11g6xr3p8y!5m1!1e2?entry=ttu&g_ep=EgoyMDI0MDgyNi4wIKXMDSoASAFQAw%3D%3D" target="_blank" >
                <img src='/images/Map.PNG' alt="map" className="w-full h-auto " />
                </a>       
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col items-center mt-24 gap-24">
          <Footer />
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
