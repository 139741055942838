// src/components/HijriCalendar.js

import React, { useEffect, useState } from 'react';
import moment from 'moment-hijri';
import './HijriCalendar.css';
import { getAllAvailabilityApi, getAllAvailabilitySlotsApi } from '../../Api';
import { useLocation } from 'react-router';
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import SlotModal from '../../Modal/SlotModal';


const HijriCalendar = () => {
  
  // fetching all the available days
  const [selectedDate, setSelectedDate] = useState(null);
  const [availability, setAvailability] = useState([])
  const [slots, setSlots] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { state } = useLocation()

  const getAvailableConsultantDays = async () => {
    try {
      let data = await getAllAvailabilityApi(state?.consultant?._id)

      let newData = Object.keys(data?.data?.data?.availability)

      // console.log('newData avalable days', newData)
      setAvailability(newData)

    } catch (error) {
      console.log(error)
    }

  }

  useEffect(() => {
    getAvailableConsultantDays()
  }, [])

  const fetchSlots = async (id, date) => {
    const res = await getAllAvailabilitySlotsApi(id, date.format('iYYYY-iM-iD'))
    setSelectedDate(date.format('iYYYY-iM-iD'))
    // console.log("2222222222   ",date.format('iYYYY-iM-iD'))
    
    // console.log("fetched slotes", res?.data?.data)
    console.log(res);
    
    setSlots(res?.data?.data);
    setIsModalOpen(true);

  };

console.log("22222222 ",selectedDate)
  const [currentDate, setCurrentDate] = useState(moment());

  const arabicDaysinWeek = [
    'Yawm al-Ahad',
    'Yawm al-Ithnayn',
    'Yawm al-Thulatha',
    "Yawm al-Arbi'a",
    "Yawm al-Khamis",
    "Yawm al-Jumu'ah",
    "Yawm al-Sabt",
  ]

  const todayHijri = moment().format('iYYYY/iM/iD');

  const renderDaysOfWeek = () => {
    const daysOfWeek = moment.weekdaysShort();

    return (
      // <div className="flex justify-between p-[5px] font-bold">
      //   {arabicDaysinWeek.map((day, index) => (
      //     <div key={index} className="day">
      //       {day}
      //     </div>
      //   ))}
      // </div>

      <div className="grid grid-cols-7 mb-2 font-bold">
        {daysOfWeek.map((day, index) => (
          <div key={index} className="sm:p-[10px] w-full border border-[#959494]">
            {day}
          </div>
        ))}
      </div>

    );
  };

  const renderDays = () => {
    const startOfMonth = moment(currentDate).startOf('iMonth');
    const endOfMonth = moment(currentDate).endOf('iMonth');
    const daysInMonth = endOfMonth.iDate();

    // hold the days and empty spaces
    const days = [];

    // today's Hijri date
    const todayHijri = moment();


    // Padding for the first week
    for (let i = 0; i < startOfMonth.day(); i++) {
      // console.log('startOfMonth.day()', startOfMonth.day())
      days.push(<div key={`empty-start-${i}`} className="bg-white border border-[#ddd] sm:p-[50px] w-full"></div>);
    }

    // Days in month
    for (let d = 1; d <= daysInMonth; d++) {
      const day = moment(startOfMonth).add(d - 1, 'days');
      // const hijriDate = day.format('iYYYY/iM/iD');
      const hijriDate = moment(day);

      // const isToday = hijriDate === todayHijri;
      // const isSelected = selectedDate && selectedDate.format('iYYYY/iM/iD') === hijriDate;

      const isToday = hijriDate.isSame(todayHijri, 'day');
      // const isSelected = selectedDate && selectedDate.isSame(hijriDate, 'day');
      const isPast = hijriDate.isBefore(todayHijri, 'day');
      const isSelectable = availability.includes(day.format('dddd')) && !isPast;

      days.push(
        <div
          key={d}
          className={`border border-[#959494] w-full p-[10px] sm:p-[50px] font-bold ${isToday ? 'bg-[#52A8B3] text-black' : ''} ${isPast ? 'bg-[#757575] text-white hover:cursor-not-allowed' : ''} ${isSelectable ? 'cursor-pointer text-black hover:bg-[#d7d7d7] hover:border hover:border-[#36535d]' : 'bg-[#757575] text-white hover:cursor-not-allowed'}`}
          onClick={() => isSelectable && fetchSlots(state?.consultant?._id, hijriDate)}
        >
          {hijriDate.format('iD')}
        </div>
      );

    }
    // console.log('days array', days)
    return <div className="grid grid-cols-7">{days}</div>;
  };

  const handleMonthChange = (increment) => {
    const newDate = moment(currentDate).add(increment, 'iMonth');
    setCurrentDate(newDate);
  };

  const handleYearChange = (increment) => {
    const newDate = moment(currentDate).add(increment, 'iYear');
    setCurrentDate(newDate);
  };
  const handleTodayClick = () => {
    setCurrentDate(moment());
  };

  return (
    <div className="w-[100%] mx-auto text-center mb-8 bg-[#f0efef] p-4 rounded-2xl">

      {/* <div className="controls">
        <button onClick={() => handleYearChange(-1)}>&lt; Prev Year</button>
        <button onClick={() => handleYearChange(1)}>Next Year &gt;</button>
      </div> */}

      <div className='flex gap-2 items-center justify-between my-4'>
        <h3 className='text-[#0b4d67] font-bold text-2xl'>{currentDate.format('iMMMM iYYYY')}</h3>
        <div className='flex gap-2 items-center'>
          <h3 className='bg-[#0b4d67] text-white px-4 py-2 h-fit rounded-lg cursor-pointer hover:bg-[#0a8291] transition-all duration-300 ease-in' onClick={() => handleTodayClick()}>Today</h3>
          <div className='flex rounded-lg overflow-hidden'>
            <div className='bg-[#0b4d67] text-white text-lg font-extralight p-3 cursor-pointer hover:bg-[#0a8291] transition-all duration-300 ease-in' onClick={() => handleMonthChange(-1)}><FaChevronLeft /></div>
            <div className='bg-[#0b4d67] text-white text-lg font-extralight p-3 cursor-pointer hover:bg-[#0a8291] transition-all duration-300 ease-in' onClick={() => handleMonthChange(1)}><FaChevronRight /></div>
          </div>
        </div>
      </div>

      {renderDaysOfWeek()}

      {renderDays()}

      {isModalOpen && <SlotModal onClose={setIsModalOpen} slots={slots} id={state?.consultant?._id} date={selectedDate} />}

    </div>
  );
};

export default HijriCalendar;
