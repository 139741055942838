import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { fetchlocalstorage, isAuth, logOut } from "../../HelperFunctions";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { ImageColor } from "../../HelperComponents";
import { signupLogedInConsultantApi } from "../../Api";
import MobileMenu from "./MobileMenu";
//----------------------------------------------------------------------------------------------------------------------------------------

export default function Header({bg}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const { t, i18n } = useTranslation();
  let [language, setLanguage] = useState("en");
  const modalRef = useRef(null);
  const modalRef1 = useRef(null);

  function toggleDropdown(status) {
    setIsDropdownOpen(status);
  }
  function toggleDropdown1(status) {
    setIsDropdownOpen1(status);
  }
  let getUserInfo = localStorage.getItem("userDetails");
  getUserInfo = JSON.parse(getUserInfo);
  let getAuth = isAuth();

  const toggleLanguage = () => {
    const newLanguage = i18n.language === "en" ? "ar" : "en";
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  };

  useEffect(() => {
    const lang = localStorage.getItem("language");
    if(!lang){
      const newLanguage = i18n.language === "en" ? "en" :  "ar";
      localStorage.setItem("language", newLanguage);
    }
    setLanguage(lang);
  }, [localStorage.getItem("language")]);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      toggleDropdown(false);
    }
  };
  const handleClickOutside1 = (event) => {
    if (modalRef1.current && !modalRef1.current.contains(event.target)) {
      toggleDropdown1(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside1);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside1);
    };
  }, []);
  async function loginAsConsultant(id) {
    try {
      let res = await signupLogedInConsultantApi(id, { isConsultant: true });
      localStorage.setItem("userDetails", JSON.stringify(res?.data?.user));
      window.location.reload();
    } catch (error) {
      toast.error(t(error?.response?.data?.message), { id: "err501" });
    }
  }
  useEffect(() => {
    window.scrollTo({top:0, behavior:"instant"}); // Scrolls to the top of the page
  }, []);

  return (
    <div
      dir={language == "en" ? "ltr" : "rtl"}
      className="text-white flex justify-between items-center w-[86%] mx-auto pt-3 h-[80px]"
    >
      {/* <h1>{language}</h1> */}
      <div className="flex justify-between items-center gap-4">
        <Link to={`/`}>
          <img
            src="/logo.png"
            alt="alMoultqa Logo"
            className={`h-12 w-20 mr-2`}
          />
        </Link>
        <select
          onChange={toggleLanguage}
          value={window.localStorage.getItem("language")}
          className={`px-1 py-2  md:hidden rounded text-black text-xs cursor-pointer ${
            window.localStorage.getItem("language") == "ar" ? "ml-8" : ""
          }`}
        >
          <option value="en">English</option>
          <option value="ar">العربية</option>
        </select>
      </div>

      <ul className="md:flex hidden justify-between gap-6 text-sm leading-[28px] font-semibold">
        {/* <Link to={`//about`} className="hover:cursor-pointer">
        {t("about")}
      </Link>  */}
        <li
          
          onClick={toggleDropdown1}
          className="hover:cursor-pointer"
        >
          {t("Navbar.about")}
        </li>

        {isDropdownOpen1 && (
          // <ul className="absolute left-50 mt-8 w-48 bg-white border border-gray-200 rounded-md shadow-lg">
          //   <li className="hover:bg-gray-100 bg-transparent">
          //     <Link to={`/about`} className="block text-black px-4 py-2">
          //       {t("Navbar.about")}
          //     </Link>
          //   </li>
          //   <li className="hover:bg-gray-100">
          //     <Link
          //       to={`/about-numbers`}
          //       className=" text-black block px-4 py-2"
          //     >
          //       {t("Navbar.aboutNumbers")}
          //     </Link>
          //   </li>
          // </ul>

          
          <ul ref={modalRef1} className="absolute left-50 mt-8 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-[9999]">
            <li className="hover:bg-gray-100 bg-transparent">
              <Link to={`/about`} className="block text-black px-4 py-2">
                {t("Navbar.about")}
              </Link>
            </li>
            <li className="hover:bg-gray-100">
              <Link
                to={`/about-numbers`}
                className=" text-black block px-4 py-2"
              >
                {t("Navbar.aboutNumbers")}
              </Link>
            </li>
            <li className="hover:bg-gray-100">
              <Link
                to={`/project-guide`}
                className=" text-black block px-4 py-2"
              >
                {t("Navbar.projectGuideValue")}
              </Link>
            </li>
            <li className="hover:bg-gray-100">
              <Link
                to={`/our-team`}
                className=" text-black block px-4 py-2"
              >
                {t("Navbar.ourTeam")}
              </Link>
            </li>
          </ul>
        )}
        <li className="hover:cursor-pointer">
          <Link to={`/held-events`}>{t("Navbar.events")}</Link>
        </li>
        <li className="hover:cursor-pointer">
          <Link to={`/donor-services`}>{t("Navbar.donor")}</Link>
        </li>
        <li className="hover:cursor-pointer">
          <Link to={`/opportunity`}>{t("Navbar.givingOppurtinities")}</Link>
        </li>
        <li className="hover:cursor-pointer">
        <Link to={`/media-centers`}>{t("Navbar.mediaCenter")}</Link>
        {/* {t("Navbar.mediaCenter")} */}
        </li>
        <Link to="/contact" className="hover:cursor-pointer">
          {t("Navbar.contactUs")}
        </Link>
        <Link to={"/faq"} className="hover:cursor-pointer">
          {t("Navbar.faq")}
        </Link>
        <select
          onChange={toggleLanguage}
          value={i18n.language}
          className={`px-1 py-2 mr-8  rounded text-black cursor-pointer ${
            i18n.language == "ar" ? "ml-8" : ""
          }`}
        >
          <option value="en">English</option>
          <option value="ar">العربية</option>
        </select>
      </ul>

      <div className="flex items-center justify-between gap-4">
        <img
          src="/images/searchIcon.svg"
          alt="searchIcon"
          width={22}
          height={22}
          className="object-cover hover:cursor-pointer"
        />

        {getAuth ? (
          <div
            ref={modalRef}
            className={`hamburger-menu menu-bar relative info-bar realtive d-inline-block  width-[200px]  ${
              fetchlocalstorage("language") == "ar" ? "mr-2" : "ml-5"
            }`}
            style={{
              zIndex: 120,
            }}
          >
            <button
              onClick={() => toggleDropdown(!isDropdownOpen)}
              className="hamburger-btn open-mobile-menu"
            >
              <i className="fal fa-bars"></i>
            </button>
            {isDropdownOpen && (
              <div
                className={`dropdown-menu absolute ${
                  fetchlocalstorage("language") === "en"
                    ? "  right-[14px]"
                    : "left-[3px] w-[200px]"
                }`}
                style={{ zIndex: "9999" }}
              >
                <span className="xs:block xl:hidden">
                  <Link
                    to="/"
                    className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    {t(`Home`)}
                  </Link>
                  <Link
                    to="/about"
                    className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    {t(`About`)}
                  </Link>
                  <Link
                    to={`/about-numbers`}
                    className=" text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    {t("Navbar.aboutNumbers")}
                  </Link>
                  <Link
                    to="/causes"
                    className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    {t(`Projects`)}
                  </Link>
                  <Link
                    to="/held-events"
                    className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    {t(`Events`)}
                  </Link>
                  <Link
                    to="/media-centers"
                    className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    {t(`Navbar.mediaCenter`)}
                  </Link>

                  {/* <Link to="/consultant" className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t(`Consultant`)}</Link> */}
                  <Link
                    to="/faq"
                    className="text-gray-700    hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    {t(`Faq`)}
                  </Link>

                  {/* <a href="#" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">News</a> */}
                  <Link
                    to="/contact"
                    className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    {t(`Contact`)}
                  </Link>
                </span>

                {getAuth ? (
                  ""
                ) : (
                  <Link
                    to="/login"
                    className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    {t(`Login Donar`)}
                  </Link>
                )}
                {getAuth ? (
                  ""
                ) : (
                  <Link
                    to="/signin-by-org"
                    className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    {t(`Login organization`)}
                  </Link>
                )}
                {getAuth ? (
                  getUserInfo?.role == "CHARITY" ? (
                    getUserInfo?.isProfileCreated ? (
                      <Link
                        to="/list-all-projects"
                        className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                      >
                        {t(`Project List`)}
                      </Link>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {/* {getAuth?JSON.parse(getUserInfo)?.role=="CHARITY"? <Link to='/organization-profile'>My profile</Link>: <Link to='/donor-profile'>My profile</Link>:""}
                 */}
                {/* {
                  getAuth && (
                    <>
                      <li className="hover:cursor-pointer hidden sm:block">
                        <Link to={`/held-events`}>{t("Navbar.events")}</Link>
                      </li>
                      <li className="hover:cursor-pointer hidden sm:block">
                        <Link to={`/donor-services`}>{t("Navbar.donor")}</Link>
                      </li>
                      <li className="hover:cursor-pointer hidden sm:block">
                        <Link to={`/opportunity`}>{t("Navbar.givingOppurtinities")}</Link>
                      </li>
                      <li className="hover:cursor-pointer hidden sm:block">
                        {t("Navbar.mediaCenter")}
                      </li>
                      <Link to="/contact" className="hover:cursor-pointer hidden sm:block">
                        {t("Navbar.contactUs")}
                      </Link>
                      <Link to={"/faq"} className="hover:cursor-pointer hidden sm:block">
                        {t("Navbar.faq")}
                      </Link>
                    </>
                  )
                } */}

                {getAuth ? (
                  //Role == Charity && profile is created show charity: my profile, else redirect to charity creation form
                  getUserInfo?.role == "CHARITY" ? (
                    //For Charity ,redirect to charity
                    getUserInfo.isProfileCreated ? (
                      <Link
                        to="/organization-profile"
                        className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                      >
                        {t(`My Profile`)}
                      </Link>
                    ) : (
                      <Link
                        to="/charity"
                        className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                      >
                        {t(`My Profile`)}
                      </Link>
                    )
                  ) : (
                    //For Donor, redirect to donation
                    ""
                  )
                ) : (
                  ""
                )}

                {getAuth ? (
                  //Role == DONOT && profile is created show donor: my profile, else redirect to donor creation form
                  getUserInfo?.role == "DONOR" ? (
                    getUserInfo.isProfileCreated ? (
                      <Link
                        to="/donor-profile"
                        className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                      >
                        {t(`My Profile`)}
                      </Link>
                    ) : (
                      <Link
                        to="/donor"
                        className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                      >
                        {t(`My Profile`)}
                      </Link>
                    )
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {getAuth ? (
                  <Link to="/my-bookings">{t(`My Booking`)}</Link>
                ) : (
                  ""
                )}
                {getAuth ? (
                  getUserInfo?.role == "CONSULTANT"  ? (
                    <Link to="/my-availability">{t(`My Availability`)}</Link>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {getAuth ? (
                  getUserInfo?.role !== "CONSULTANT"  ? (
                    <Link to="/consultant">{t(`Book Consultancy`)}</Link>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {getAuth ? (
                  getUserInfo?.role == "CONSULTANT" ? (
                    <Link to="/consultant-profile">{t(`My Profile`)}</Link>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {getAuth ? (
                  getUserInfo?.isProfileCreated ? (
                    <Link to="/chat">{t(`Chats`)}</Link>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {getAuth ? (
                  getUserInfo?.isProfileCreated &&
                  getUserInfo?.role == "CHARITY" ? (
                    <Link
                      to="/get-all-workshop"
                      className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                    >
                      {t(`My Workshop`)}
                    </Link>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {getAuth ? (
                  getUserInfo?.isProfileCreated &&
                  getUserInfo?.role == "DONOR" ? (
                    <Link
                      to="/my-donations"
                      className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                    >
                      {t(`My Donations`)}
                    </Link>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                 {getAuth ? (
                  getUserInfo?.isProfileCreated &&
                  getUserInfo?.role == "DONOR" && (
                    <Link
                    to='/ticket-support'
                    className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                    >
                     {t(`Ticket Support`)}<span></span>
                   </Link>
                  ) 
                ) : (
                  ""
                )}

                {getAuth ? (
                  getUserInfo?.role == "CHARITY" ||
                  getUserInfo?.isConsultant ? (
                    <Link to="/my-analytics">{t(`My Analytics`)}</Link>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {/* {getAuth ?  getUserInfo.role != "CONSULTANT"&& !getUserInfo.isConsultant?<Link onClick={()=>loginAsConsultant(getUserInfo._id)} className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t(`Signup Consultant`)}</Link> : "":""} */}

                {!getAuth ? <Link to='/login-consultant' className='text-gray-700   hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium'>{t(`Login Consultant`)}</Link> : ""}

                {getAuth ? (
                  <Link
                    to="/"
                    onClick={() => logOut()}
                    className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    {t("Log Out")}
                  </Link>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        ) : window.innerWidth < 768 ? (
          <MobileMenu />
        ) : (
          <Link to={`/login`}>
            <button className="bg-[#E8C572] rounded-[40px] px-8 py-2 text-black font-medium">
              {t("Navbar.login")}
            </button>
          </Link>
        )}
      </div>
    </div>
  );
}
