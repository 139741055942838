import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { verifyEmailApi } from '../../../Api';
import { useTranslation } from 'react-i18next';

function VerifyEmail() {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [verificationSuccess, setVerificationSuccess] = useState(false);

  useEffect(() => {
    // Call the verifyEmail API with the token from the URL
    const verifyEmail = async () => {
      try {
        const res = await verifyEmailApi(token);  // API call to verify email
        if (res.status === 200 || res.status === 201) {
          toast.success(t('Your account has been successfully verified'), { id: 'success' });
          setVerificationSuccess(true);
          // After successful verification, navigate to login or profile
          navigate('/login', { replace: true });
        } else {
          toast.error(t('Verification failed'), { id: 'error' });
          setVerificationSuccess(false);
        }
      } catch (error) {
        toast.error(t('Invalid or expired verification link'), { id: 'error' });
        setVerificationSuccess(false);
      } finally {
        setLoading(false);
      }
    };
    
    verifyEmail();
  }, [token, t, navigate]);

  if (loading) return <div>{t('Verifying...')}</div>;

  return (
    <div className='verification-container'>
      {verificationSuccess ? (
        <h1>{t('Account Verified Successfully')}</h1>
      ) : (
        <h1>{t('Verification Failed')}</h1>
      )}
    </div>
  );
}

export default VerifyEmail;
