import React, { useState, useEffect } from 'react'
import DonorDetails from '../Components/Donor/DonationForm/DonorDetails'
import AgentDetails from '../Components/Donor/DonationForm/AgentDetails'
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import { createDonorApi } from '../Api';
import Navbar from '../Components/Nav_Form/Navbar';
import { useTranslation } from 'react-i18next';
import { fetchlocalstorage } from '../HelperFunctions';
import Header from '../Components/UI/Header';

const Donation = () => {
    const [step, setStep] = useState(1);
    const [step1Data, setStep1Data] = useState({});
    const [step2Data, setStep2Data] = useState({});
    const {t} = useTranslation()
    const [loading,setLoading] = useState(true)


    const navigate = useNavigate()
    const handleStep1Submit = data => {
      setStep1Data(data);
      console.log(data)
      setStep(2);
    };
    
    const handleStep2Submit = async data => {
      setStep2Data(data);
      let payload =  { ...step1Data, ...step2Data ,...data}
      payload = {
        ...payload,
        domain:payload?.domain?.map(el => el?.value)
      }
     
      try {
        setLoading(false)
        const res = await createDonorApi(payload);
        // console.log(res)
        if (res.status === 200 || res.status === 201) {
            
            toast.success(t(res?.data?.message), { id: "001" });
            //if donar created successfully update isProfile true for furter validation
            const data = JSON.parse(localStorage.getItem('userDetails'));
            data.isProfileCreated = true;
            localStorage.setItem('userDetails', JSON.stringify(data));
            setLoading(true)

            navigate("/donor-profile", { replace: true });
        
        }else {
          // toast.error(res?.response?.data?.message, { id: "err501" });
  
          setLoading(true)
        }
      } catch (error) { 
        toast.error(t(error?.response?.data?.message), { id: "err501" });
      }
  
   
    };
    
    useEffect(() => {
      const handleBeforeUnload = () => {
        sessionStorage.removeItem('donorFormData1');
        sessionStorage.removeItem('donorFormData2');
      };
  
      // Add event listener to handle page reloads
      window.addEventListener('beforeunload', handleBeforeUnload);
  
      // Cleanup function to remove the event listener when the component unmounts
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
      
    }, []);
    const handleBack = () => {
      setStep(1);

    };
  
  return (
    <>
     <section className="bg-[#0A4D69] h-[10vh] flex flex-col  relative">
          <Header />
     </section>
    <div className="bg-white py-8 px-4  shadow-md rounded-lg my-5 mx-auto   max-w-4xl ">
      {/* Header Section */}
      <div className="text-center pb-8">
        <h1 className="text-2xl font-semibold">{t("Let's get you started")}</h1>
        <p className="text-gray-600">{t(`Enter the details to get going`)}</p>
      </div>

      {/* Stepped Section Indicators */}
      <div className="flex justify-between text-center mb-6">
        <div className="w-1/2">
          <div className={`inline-block w-8 h-8 ${step==1 ?"bg-blue-500 text-white":"bg-gray-300 text-gray-600"}  rounded-full text-lg leading-loose  ${fetchlocalstorage("language")=="ar"?"ml-2":""}`}>1</div>
          <span className="text-sm font-semibold text-gray-700 ml-2">{t(`Donor Details`)}</span>
        </div>
        <div className="w-1/2">
          <div className={`inline-block w-8 h-8 ${step==2?"bg-blue-500 text-white":"bg-gray-300 text-gray-600"} text-gray-600 rounded-full text-lg leading-loose ${fetchlocalstorage("language")=="ar"?"ml-2":""}`}>2</div>
          <span className="text-sm font-semibold text-gray-400 ml-2">{t(`Agent Details`)}</span>
        </div>
        
      </div>

      {step==1?<DonorDetails onSubmit={handleStep1Submit}/>:""}
      {step==2?<AgentDetails onSubmit={handleStep2Submit} loading={loading} onBack={handleBack}/>:""}
      </div>
    </>
  )
}

export default Donation
