import React, { useEffect, useState } from 'react';
import { callDomainForOrg, createConsultantApi, getProfile } from '../../Api';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { FaPen } from "react-icons/fa";
import Navbar from '../Nav_Form/Navbar';
import Footer from '../Footer/Footer';
import Header from '../UI/Header';
import { fetchlocalstorage } from '../../HelperFunctions';
// import Select from 'react-select';  // for multi-select input
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Grid,
    TextField,
    Typography,
    MenuItem,
    FormControl,
    InputLabel,
    Chip,
    styled,
} from '@mui/material';
import Select from "react-select";
import toast from 'react-hot-toast';

const StyledCard = styled(Card)(({ theme }) => ({
    maxWidth: 600,
    margin: 'auto',
    marginTop: theme.spacing(4),
}));

const StyledForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
}));
const ConsultantProfile = () => {
    const [userInfo, setUserInfo] = useState({});
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [domain, setDomain] = useState([])


    async function getProfileData() {
        let data = await getProfile();
        console.log("consultantProfileData->", data?.data?.data);
        setUserInfo(data?.data?.data);
    }

    useEffect(() => {
        getProfileData();
        getAllDomain()
    }, []);

    async function getAllDomain(params) {
        try {
            const data = await callDomainForOrg();
            const newData = data?.data?.domains.map((domain) => ({
                value: domain?._id,
                label: domain?.name,
            }));
            setDomain(newData);
        } catch (error) {
            console.log(error);
        }
    }

    const formik = useFormik({
        initialValues: {
            name: userInfo?.name || '',
            email: userInfo?.email || '',
            domain: userInfo?.domain || [], // Assuming domain is an array of selected values
        },
        enableReinitialize: true, // Important to update form values after fetching data
        validationSchema: yup.object({
            name: yup.string().required(t('Name is required')),
            email: yup.string().email(t('Invalid email')).required(t('Email is required')),
            domain: yup.array().min(1, t('At least one domain must be selected')).required(),
        }),
        onSubmit: async (values) => {
            console.log("Form values: ", values);
            handleSubmit(values)
            // Submit the form with the new values, such as name, email, and domain
            // You can call an API to update the profile here
        }
    });

    const handleDomainChange = (selectedOptions) => {
        formik.setFieldValue('domain', selectedOptions);
    };
    const getSelectedOptions = (selectedValues) => {
        return selectedValues.map((value) => {
            return domain.find(option => option.value === value);
        });
    };

    const handleSubmit = async data => {
        let payload = {  ...data }
        try {
          setLoading(false)
          const res = await createConsultantApi(payload);
          console.log('res', res);
          // console.log(res)
          if (res.status === 200 || res.status === 201) {
    
            toast.success(res?.data?.message, { id: "001" });
            //if donar created successfully update isProfile true for furter validation
            const data = JSON.parse(localStorage.getItem('userDetails'));
            data.isProfileCreated = true;
            localStorage.setItem('userDetails', JSON.stringify(data));
            setLoading(true)
            toast.success(t("Consultant Details Saved Successfully"))
            // navigate("/organization-profile", { replace: true, state: { previousPath: currentPath } });
            // navigate('/my-availability',{replace:true});
    
          } else {
            // toast.error(res?.response?.data?.message, { id: "err501" });
    
            setLoading(true)
          }
    
        } catch (error) {
          toast.error(t(error?.response?.data?.message), { id: "err501" });
        }
    
    
      };

    return (
        <div>
            <section className="bg-[#0A4D69] h-[11vh] flex flex-col z-40 relative">
                <Header />
            </section>

            <div className="bg-white p-4 sm:rounded-lg xs:m-[1rem] sm:m-[5rem] md:m-[7rem] shadow-lg border">
                <Container>


                        <CardHeader

                            title={<Typography variant="h5">{t('Profile Details')}</Typography>}
                        //   subheader={t('Manage your personal information')}
                        />
                        <CardContent>
                            <StyledForm onSubmit={formik.handleSubmit}>
                                <div>
                                    <label
                                        htmlFor="domain"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t(`Name`)}
                                    </label>
                                    <TextField
                                        fullWidth
                                        id="name"
                                        name="name"
                                        size='small'

                                        //   label={t('Name')}
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="domain"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t(`Email`)}
                                    </label>
                                    <TextField
                                    size='small'
                                    fullWidth
                                    id="email"
                                    name="email"
                                    disabled
                                    //   label={t('Email')}
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                                </div>


                               

                               { domain && <FormControl fullWidth>
                                    <div className="">
                                        <label
                                            htmlFor="domain"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            {t(`Organization Field`)}
                                        </label>
                                        <Select
                                            id="domain"
                                            name="domain"
                                            isMulti
                                            options={domain}
                                            placeholder={t("Select")}
                                            className="mt-1 block w-full"
                                            classNamePrefix="react-select"
                                            // value={formik.values.domain}
                                            value={getSelectedOptions(formik.values.domain)}

                                            // onChange={(value) => formik.setFieldValue("domain", value)}
                                            onChange={(value) => formik.setFieldValue("domain", value.map(option => option.value))}

                                        // onBlur={() => formik.setFieldTouched('domain', true)}
                                        />

                                        {formik.touched.domain && formik.errors.domain ? (
                                            <p className="text-red-500">{formik.errors.domain}</p>
                                        ) : null}
                                    </div>
                                </FormControl>}

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    sx={{ alignSelf: 'flex-end' }}
                                >
                                    {loading ? t('Saving...') : t('Save Changes')}
                                </Button>
                            </StyledForm>
                        </CardContent>
                </Container>
            </div>

            <Footer />
        </div>
    );
};

export default ConsultantProfile;
