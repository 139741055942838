import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { t } from "i18next";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import Navbar from "../Components/Nav_Form/Navbar";
import { resetPasswordApi } from "../Api";
import Header from "../Components/UI/Header";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  console.log("id", id);

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: yup.object({
      newPassword: yup
        .string()
        .min(6, <>{t(`password must be at least 6 characters`)}</>)
        .required(<>{t("New Password is required")}</>),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("newPassword"), null], <>{t("Passwords must match")}</>)
        .required(<>{t("Confirm Password is required")}</>),
    }),
    onSubmit: async (values) => {
      console.log("values", values);
      console.log("clicked on it ");
      const payload = {
        id,
        newPassword: values.newPassword,
      };
      try {
        setLoading(true);
        const res = await resetPasswordApi(payload);

        if (res.status === 200 || res.status === 201) {
          toast.success(t(res?.data?.message), { id: "001" });
          console.log("res.data", res.data);
          if (res.data.accountType === "CHARITY") {
            navigate("/signin-by-org");
          }
          if (res.data.accountType === "DONOR") {
            navigate("/login");
          }
          if (res.data.accountType === "CONSULTANT") {
            navigate("/login-consultant");
          }
          setLoading(false);
          navigate("/login");
        } else {
          setLoading(false);
        }
      } catch (error) {
        toast.error(t(error?.response?.data?.message), { id: "err501" });
      }
    },
  });

  return (
    <div className="h-screen w-screen overflow-hidden">
    <section className="bg-[#0A4D69] h-[10vh] flex items-center">
      <Header />
    </section>
    <div className="flex justify-center items-center h-full">
      <div className="h-auto max-w-md w-[90%] p-5 shadow-lg rounded-md border py-10 flex flex-col gap-4 items-center">
        <h1 className="text-3xl text-[#263b5c] text-center">{t("Reset Password")}</h1>
        <form className="mb-4 mt-4 w-full" onSubmit={formik.handleSubmit}>
          <label htmlFor="newPassword" className="w-full">
            {t("New Password")} <span className="text-red-600">*</span>
          </label>
          <input
            type="password"
            name="newPassword"
            id="newPassword"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder={t("Enter New Password")}
            {...formik.getFieldProps("newPassword")}
          />
          {formik.touched.newPassword && formik.errors.newPassword ? (
            <p className="text-red-500">{formik.errors.newPassword}</p>
          ) : null}
  
          <label htmlFor="confirmPassword" className="mt-4 w-full">
            {t("Confirm Password")} <span className="text-red-600">*</span>
          </label>
          <input
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder={t("Confirm New Password")}
            {...formik.getFieldProps("confirmPassword")}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <p className="text-red-500">{formik.errors.confirmPassword}</p>
          ) : null}
  
          <button
            type="submit"
            className="inline-flex mt-5 mx-auto justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500"
          >
            {t(`resetPasswordSubmit`)}
          </button>
        </form>
      </div>
    </div>
  </div>
  
  );
};

export default ResetPassword;
