import React from "react";
import { Toaster } from "react-hot-toast";
// import PRoute from "./PrivateRoute";  
import { RouterProvider, createBrowserRouter, useRoutes } from "react-router-dom";
import PRoute from "./PrivateRoute";
import AppLayout from '../pages/AppLayout'
import Homepage1 from '../Components/UI/Homepage1'
import Aboutsection from '../Components/UI/Aboutsection'
// import Causebreadcums from '../Components/Causebreadcums'
import Causedetails1 from '../Components/Causedetails1'
import Eventbreadcums from '../Components/Eventbreadcums'
import Faqpage from '../Components/Faqpage'
import FAQ from "../Components/UI/FAQ";
import Donation from '../Components/Donation'
import UserDonation from "../pages/Donation"
import Contact from '../Components/Contact'
// import Login from '../Components/Login'
import LoginByOrg from "../Components/Auth/Organization/Login"
import DemoProjectDetail from '../pages/DemoProjectDetail'
// import Loginorganization from '../Components/Loginorganization'; 
// import Organizationsubmit from '../Components/Organizationsubmit';
// import Signup from '../Components/Signup'
// import Register from '../Components/Register'
// import Formsubmit from '../Components/Nav_Form/Formsubmit'
import DemoOrganizationProfile from '../Components/Organization/DemoOrganizationProfile'
import Organizationprofile from '../Components/Organization/Organizationprofile'
import Error404 from "../Components/Error404/Error404";
import CreateProject from "../pages/CreateProject";
import ProjectsList from "../pages/ProjectsList";
import ProjectDetail from "../pages/ProjectDetail";
import SignUp from "../Components/Auth/Organization/Signup";
import DonorSignUP from "../Components/Auth/Donor/Signup"
import Charity from "../pages/Charity";
import Login from "../Components/Auth/Donor/Login";
import DonorProfile from "../Components/Donor/DonorProfile";
import Chat  from "../Components/Chat/Chat";
import { isAuth } from "../HelperFunctions";
import PublicRoute from "./PublicRoute";
import PRouteForOrg from "./PrivateRouteForOrg";
import UpdateProject from "../pages/UpdateProject";
import UpdateCharity from "../pages/UpdateCharity";
import UpdateDonation from "../pages/UpdateDonation";
import WorkshopList from "../pages/WorkshopList";
import WorkshopDetail from "../pages/WorkshopDetail";
import Event from "../pages/Event";
import CausesDetails from "../pages/CauseDetails";
import LoginConsultant from "../Components/Auth/Consultant/Login"
import SignUpConsultant from "../Components/Auth/Consultant/Signup"
import ConsultantAvailability from "../pages/ConsultantAvailability";
import Consultant from "../pages/Consultant";
import ConsultantDetail from "../Components/Consultant/ConsultantDetail";
import MyBookings from "../pages/MyBookings";
import PaymentSuccess from "../pages/PaymentSuccess";
import MakePayment from "../pages/MakePayment";
import MyDonations from "../pages/MyDonations";
import MakePaymentByReserve from "../pages/MakePaymentByReserve";
 
import ConsultantProfile from "../Components/Consultant/ConsultantProfile";
import TicketSupport from "../Components/ticket-support/TicketSupport";
import ThankYou from "../pages/ThankYou";
import ForgetEmailPage from "../pages/ForgetEmail";
import ResetPassword from "../pages/ResetPassword";
import InputValue from "../pages/InputValue";
import DemoProject from "../pages/DemoProject";
import DemoUpdateProject from "../pages/DemoUpdateProject";
import LoginPage from "../Components/UI/Login";
import DonorService from "../Components/UI/DonorService";
import { AboutNumbers } from "../Components/UI/AboutNumbers";
import HeldEvents from "../Components/UI/HeldEvents";
import Givingopportunity from "../Components/UI/Givingopportunity";
import CharityDashboard from "../Components/UI/CharityDashboard";
import ContactUs from "../Components/UI/ContactUs";
import ProjectGuidePage from "../Components/ProjectGuide/ProjectGuidePage";

import OurTeam from "../Components/OurTeam/OurTeamPage";
// import OurTeam from "../Components/OurTeam/OurTeam";

import MediaCenters from "../Components/UI/MediaCenters";
import MediaCenterDetails from "../Components/UI/MediaCenterDetails";
import VerifyEmail from "../Components/Auth/Organization/VerifyEmail";

export default function MainRouter() {


  const routes = createBrowserRouter([
    {
      path: '/',
      element: <AppLayout />,
      children: [
        { path: '/', element: <Homepage1 /> },
        { path: '/about', element: <Aboutsection /> },
        { path: '/project-guide', element: <ProjectGuidePage /> },
        { path: '/our-team', element: <OurTeam/> },
        { path: '/about-numbers', element: <AboutNumbers /> },
        // { path: '/causes-details', element: <Causebreadcums /> },
        { path: '/causes-details', element: <CausesDetails /> },
        { path: '/causes', element: <Causedetails1 /> },
        { path: '/event', element: <Event /> },
        { path: '/faq', element: <FAQ /> },
        {path:"/consultant",element:<Consultant/>},
        {path:"/consultant-detail/:id",element:<ConsultantDetail/>},

        // { path: '/donation', element: <Donation /> },
        { path: '/contact', element: <ContactUs /> },
      ]
    },
    //Donor
    
    { path: '/login', element: <PublicRoute><LoginPage /></PublicRoute> },
    { path: '/verify/:token', element: <PublicRoute><VerifyEmail /></PublicRoute> },
    { path: '/register', element:<PublicRoute> <SignUp /> </PublicRoute> },
    { path: '/donor-services', element:<PublicRoute> <DonorService /> </PublicRoute> },
    { path: '/held-events', element:<PublicRoute> <HeldEvents /> </PublicRoute> },
    { path: '/media-centers', element:<PublicRoute> <MediaCenters /> </PublicRoute> },
    { path: '/media-centers/:id', element:<PublicRoute> <MediaCenterDetails /> </PublicRoute> },
    { path: '/opportunity', element:<PublicRoute> <Givingopportunity /> </PublicRoute> },
    { path: '/donor', element: <UserDonation /> },
    // { path: '/charity-dashboard', element: <CharityDashboard /> },
    { path: '/forget-password',element:<PublicRoute><ForgetEmailPage/></PublicRoute>},
    { path: '/resetpassword/:id',element:<PublicRoute><ResetPassword/></PublicRoute>},
    { path: '/thankyou', element:<ThankYou/>},

    // { path: '/formsubmit', element: <Formsubmit /> },
    { path: '/donor-profile', element: <DonorProfile /> },
    { path: '/update-donor', element: (<PRoute><UpdateDonation /></PRoute>) },

    //Org
    // { path: '/signin-by-org', element:<PublicRoute><LoginByOrg /></PublicRoute>  },
    // { path: '/signin-by-org', element:<PublicRoute><Login /></PublicRoute>  },
    { path: '/register-by-org', element: <PublicRoute><SignUp /></PublicRoute> },
    { path: '/charity', element: (<PRoute><Charity /></PRoute>) },
    { path: '/update-charity', element: (<PRoute><UpdateCharity /></PRoute>) },
    { path: '/organization-profile', element: <DemoOrganizationProfile/> },
    // { path: '/chat', element: <PRouteForOrg> <Chat /></PRouteForOrg> },
    // { path: '/chat', element:  <Chat /> },
    // { path: '/organizationsubmit', element: <Organizationsubmit /> },
    //Project
    { path: '/create-project', element: <PRoute><DemoProject /></PRoute> },
    { path: '/update-project', element: <DemoUpdateProject/> },
    { path: '/list-all-projects', element: <PRouteForOrg> <ProjectsList /></PRouteForOrg> },
    // { path: '/list-all-projects', element: <ProjectsList /> },
    { path: '/project-detail', element: <DemoProjectDetail /> },

    //Workshop
    { path: '/get-all-workshop', element: <WorkshopList /> },
    // { path: '/workshop-detail', element: <WorkshopDetail /> },
    //Consultant
    {path:"/login-consultant",element:<LoginConsultant/>},
    {path:"/signup-consultant",element:<SignUpConsultant/>},
    {path:"/my-availability",element:<ConsultantAvailability/>},
    {path:"/my-bookings",element:<MyBookings/>},
    {path:"/my-donations",element:<MyDonations/>},
    {path:"/consultant-profile",element:<ConsultantProfile />},
    
    {path:"/payment-success",element:<PaymentSuccess/>},
    {path:"/make-payment",element:<MakePayment/>},
    // {path:"/make-payment-by-reserve",element:<MakePaymentByReserve/>},
    {path:"/my-analytics",element:<PRouteForOrg> <ProjectsList /></PRouteForOrg>},

    {path:"/chat",element:<Chat/>},
    {path:"/ticket-support", element:<PRoute><TicketSupport/></PRoute>},
    
    


    {
      path: '/*', // Catch-all route for 404 errors
      element: (
        // <PRoute>
          <Error404 />
        // </PRoute>
      ),
    },
  ]);


  return (
    <>

      <RouterProvider router={routes}></RouterProvider>
      <Toaster position='top-center' reverseOrder={false} />
    </>
  );
}