import React from 'react'
import Aboutfootertoparea2 from '../Components/Aboutfootertoparea2';
import ConsultantPage from "../Components/Consultant/Consultant"
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchlocalstorage } from '../HelperFunctions';
import Header from '../Components/UI/Header';

const Consultant = () => {
    const {t} = useTranslation()
  return (
    <>
     <section className="bg-[#0A4D69] h-[11vh] flex flex-col z-40 relative">
        <Header />
      </section>
    <section className="breadcrumb_area breadcrumb_overlay">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="breadcrumb_section">
                            <ul className="breadcrumb-list volunteer_breadcrumb">
                                <li className="bhas_border text-black"><Link to="/" className='text-black'>{t(`Home`)}</Link></li>
                                <li className={`${fetchlocalstorage('language') == "ar" ? "mr-4 !important" : ""}`}><span className="active">{t(`Consultant`)}</span></li>
                            </ul>
                            <div className="breadcrumb_title">
                                <h2 className='text-black'>{t(`Consultant`)}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    <ConsultantPage/>
<Aboutfootertoparea2/>

    </>
  )
}

export default Consultant