import {
  getApiWithoutToken,
  getApiWithToken,
  imgUploadApi,
  postApiWithoutToken,
  postApiWithToken,
  putApiWithoutToken,
  putApiWithToken,
  simpleApiWithoutToken,
} from "./api-interface";


// Org Auth
export const loginOrgApi = (payload) => {
  return postApiWithoutToken(`/auth/generate-login-otp`, payload);
};
export const verifyOtpLoginApi = (payload) => {
  return postApiWithoutToken(`/auth/verify-login-otp`, payload);
};
export const verifyEmailApi = (token) => {
  return getApiWithToken(`/auth/verify/${token}`);
};
export const signupOrgApi = (payload) => {
  return postApiWithoutToken(`/auth/signup`, payload);
}

export const forgetPasswordApi=(payload)=>{
  return simpleApiWithoutToken('/auth/forgotPassword',payload);
}

export const resetPasswordApi=(payload)=>{
  return simpleApiWithoutToken(`/auth/resetPassword/${payload.id}`,{"password":payload.newPassword});
}

//Charity
export const createCharityApi = (payload) => {
  return postApiWithToken(`/charity/createCharity`, payload);
}
export const callDomainForOrg = () => {
  return getApiWithToken('/domain/getAllDomains')
}
export const updateCharityApi = (payload, id) => {
  return putApiWithToken(`/charity/updateCharity/${id}`, payload);

}



//Copying same api for login and signup : if we need to change the api in future

//Donor
export const loginDonorApi = (payload) => {
  return postApiWithoutToken(`/auth/login`, payload);
};
export const signupDonorApi = (payload) => {
  return postApiWithoutToken(`/auth/signup`, payload);
}
export const createDonorApi = (payload) => {
  return postApiWithToken("/donor/createDonor", payload)
}
export const updateDonorApi = (payload, id) => {
  return putApiWithToken(`/donor/updateDonor/${id}`, payload);

}
//Project
export const creatProjectApi = (payload) => {
  return postApiWithToken('/project/createProject', payload)
}
export const getAllProjectApi = (page, limit,filter) => {
  return getApiWithToken(`/project/getAllProjects?page=${page}&limit=${limit}&status=${filter}`)
}
export const getProjectDetailApi = (payload) => {
  return getApiWithToken(`/project/getProjectDetails/${payload}`)
}
export const getProjectComments = (payload) => {
  return getApiWithToken(`/projectcomment/getAllProjectComments/${payload}`)
}
export const updateReportApi = (payload, id) => {
  return putApiWithToken(`/project/updateProject/${id}`, payload)
}

//Workshop
export const getAllWorkShopApi = (payload, id) => {
  return getApiWithToken(`/workshop/getAllWorkshops`)
}

//Payment

export const createPaymentApi = (payload)=>{
return postApiWithoutToken(`/payment/createPayment`,payload)
}
export const paymentCallbackApi = (payload)=>{
  return postApiWithoutToken(`/payment/paymentCallback`,payload)
  }
export const updatePaymentApi = (payload, id)=>{
  return putApiWithoutToken(`/payment/updatePayment/${id}`,payload)
  }
  


//event 

export const getAllEventApi = (filter,page, limit)=>{
  return getApiWithoutToken(`/event/getAllApprovedEvents?domain=${filter}&page=${page}&limit=${limit}`)
}

//causes

export const getAllProjectsApi = (filter,page, limit)=>{
  return getApiWithoutToken(`/project/getAllLiveProjects?domain=${filter||""}&page=${page ||""}&limit=${limit||""}`)
}

//FAQ

export const getAllFaq = (filter)=>{
  return getApiWithoutToken(`/faq/getAllFaqs?search=${filter||""}`)
}

//Consultant

export const loginConsultantApi = (payload) => {
  return postApiWithoutToken(`/auth/login`, payload);
};
export const signupConsultantApi = (payload) => {
  return postApiWithoutToken(`/auth/signup`, payload);
}
 
export const signupLogedInConsultantApi = (id,payload) => {
  return putApiWithToken(`/user/updateSubAdmin/${id}`, payload);
}

export const getAllConsultantApi =  (filter,page, limit)=>{
  return getApiWithoutToken(`/user/getAllConsultant?domain=${filter}&page=${page}&limit=${limit}`)
}

export const createAvailabilityApi = (payload)=>{
  return postApiWithToken(`/availability/createAvailability`,payload)
}

export const getAllAvailabilityApi = (domain)=>{

  return getApiWithoutToken(`/availability/getAvailability/${domain}`)

}

export const getAllAvailabilitySlotsApi = (id,date)=>{
  return getApiWithoutToken(`/slot/getAvailableSlots?consultant=${id}&date=${date}`)
}

export const bookSlotsApi = (payload)=>{
  return postApiWithoutToken(`/slot/bookSlot`,payload)
}

export const createConsultantApi = (payload) => {
  return postApiWithToken(`/consultant/createConsultant`, payload);
}


//Bookings


export const getALlBookingsApi = (date)=>{
  return getApiWithToken(`/slot/getBookings?date=${date}`)
}

//Donations


export const getALlDoationsApi = (date)=>{
  return getApiWithToken(`/payment/getAllDonations?date=${date}`)
}
export const getALlReserveApi = (date)=>{
  return getApiWithToken(`/payment/getAllReserveDonations?date=${date}`)
}
//Common 

export const getProfile = () => {
  return getApiWithToken(`/auth/profile`);
} 


//Analytics

export const getAnalyticsApi = (page, limit) => {
  return getApiWithToken(`/project/getCharityAnalytics`)
}


//Evaluation

export const getProjectEvaluationApi = (id) => {
  return getApiWithToken(`/evaluation/getProjectRating/${id}

  `)
}



// Region
export const getRegions = ()=>{
  return getApiWithoutToken('/region/getAllRegions')
}

// cities
export const getCities = (areaId)=>{
  return getApiWithoutToken(`/city/getCity/${areaId}`)
}

export const getAllMediaCenterApi = (page, limit)=>{
  return getApiWithoutToken(`/mediacenter/getAllMediaCenter`)
}