import React, { useEffect, useRef, useState } from "react";

// Swiper components, modules and styles
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { getAllMediaCenterApi } from "../../Api";
import { backendUrl } from "../../axios";
import { useParams } from "react-router";

const DetailMediaCenter = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();



  const [details, setDetails] = useState({});
  const [leftSectionData, setLeftSectionData] = useState([]);
  const swiperRef = useRef(null);

  useEffect(() => {
    // Fetch data from API
    axios.get(`${backendUrl}/mediacenter/getMediaCenterById/${id}`)
      .then(response => {
        // Assuming we want to display the first three items in the left section
        const data = response.data.data;
        setDetails(data)
      })
      .catch(error => {
        console.error("There was an error fetching the media center data!", error);
      });
  }, []);


  return (
    <div className="py-4 mb-12">
      <div className="w-[90%] mx-auto flex flex-col gap-12">
        <h2
          className={`${i18n.language == "ar" ? "cairo" : "manrope"
            }text-[#0A4E68] text-4xl text-center font-medium tracking-wide`}
        >
          {/* {t("MEDIA CENTER")} */}
        </h2>
        <div className="p-5 bg-white">
          <div className="flex flex-col items-center">
          <div className="flex flex-col md:flex-row gap-8 items-center rounded-lg overflow-hidden h-auto ">
          <img
                src={details?.url}
                alt={details?.title}
                className="object-cover h-[100%] w-full"
              />
              
            </div>
            <div className="flex flex-col gap-6 justify-between px-8 mt-4">
              <p className="text-[#013C4A] font-medium text-sm">
                {details?.title}
              </p>
              <p className="text-[#084F67] text-xs leading-5 font-light tracking-wide">
                {details?.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    
  );
};


export default DetailMediaCenter