// import React from "react";
// import { useTranslation } from "react-i18next";
// import Header from "../UI/Header";
// import Footer from "../Footer/Footer";

// // Updated data for team members and arbitrators with titles
// const teamMembers = [
//   {
//     name: "Mr. Sami bin Abdulaziz Zlly",
//     jobTitle: "Strategic Planning Expert",
//     imageUrl: "img/ourTeam/exp-1.jpeg", 
//   },
//   {
//     name: "Eng. Talal bin Youssef Al-Suwail",
//     jobTitle: "Project Management Expert",
//     imageUrl: "img/ourTeam/exp-2.jpg", 
//   },
//   {
//     name: "Mr. Abdulghani bin Hammad Al-Ansari",
//     jobTitle: "Financial Management Expert",
//     imageUrl: "https://via.placeholder.com/150", 
//   },
//   {
//     name: "Dr. Essam bin Mustafa Sharifi",
//     jobTitle: "Public Relations Expert",
//     imageUrl: "img/ourTeam/exp-4.jpg", 
//   },
//   {
//     name: "Mr. Ali bin Saleh Al-Qazlan",
//     jobTitle: "Project Manufacturing and Maturity Expert",
//     imageUrl: "img/ourTeam/exp-5.jpg", 
//   },
//   {
//     name: "Dr. Mustafa bin Ali Alawi",
//     jobTitle: "Resource Development Expert",
//     imageUrl: "img/ourTeam/exp-6.png", 
//   },
// ];

// const arbitrators = [
//   {
//     name: "Dr. Hassan bin Mohammed Shuraim",
//     jobTitle: "Secretary General of Al-Subaie Charitable Foundation",
//     imageUrl: "img/ourTeam/arb-1.jpeg",
//   },
//   {
//     name: "Dr. Fahd bin Hamad Al-Duailej",
//     jobTitle: "Executive Director of Saad and Abdulaziz Al-Mousa Endowment",
//     imageUrl: "img/ourTeam/arb-2.jpg",
//   },
//   {
//     name: "Dr. Fahd bin Ali Al-Alayan",
//     jobTitle: "Human Resources Management Director",
//     imageUrl: "img/ourTeam/arb-3.jpg",
//   },
//   {
//     name: "Eng. Musa bin Mohammed Al-Mousa",
//     jobTitle: "Strategic Planning Director",
//     imageUrl: "img/ourTeam/arb-4.jpg",
//   },
//   {
//     name: "Dr. Younis Salwati",
//     jobTitle: "Resource Development Director",
//     imageUrl: "img/ourTeam/arb-5.jpg",
//   },
// ];

// const OurTeam = () => {
//   const { t, i18n } = useTranslation();

//   return (
//     <section className="w-full bg-white">
//       <section className="bg-[#0A4D69] h-[50vh] flex flex-col relative">
//         <Header />
//       </section>
//       <div
//         className="w-[100%] bg-white"
//         style={{ marginTop: "-300px" }}
//       >
//         <div className="w-[80%] mx-auto flex flex-col gap-14 relative z-80 mt-20 max-md:mt-14 max-sm:mt-6">
//           <h3
//             className={`${
//               i18n.language === "ar" ? "cairo" : "manrope"
//             } text-6xl text-center text-[#084F67] tracking-wide z-100`}
//           >
//             {t("OurTeam.header", "Our Team")}
//           </h3>
          
//           {/* Experts Section */}
//           <div
//             className="w-[100%] py-32 bg-white rounded-lg z-100"
//             style={{ marginTop: "-20px" }}
//           >
//             <h2
//               className={`text-5xl text-center font-bold leading-[3.5rem] ${
//                 i18n.language === "ar" ? "cairo" : "manrope"
//               }`}
//               style={{ color: "#084F67" }}
//             >
//               {t("OurTeam.header", "Experts")}
//             </h2>
//             <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 mt-12">
//               {teamMembers.map((member, index) => (
//                 <div key={index} className="flex flex-col items-center">
//                   <div className="p-2 bg-[#084F67] rounded-full">
//                     <img
//                       src={member.imageUrl}
//                       alt={member.name}
//                       className="w-32 h-32 rounded-full border-4 border-white shadow-lg"
//                     />
//                   </div>
//                   <h3 className="mt-4 text-xl font-semibold text-[#084F67]">{member.name}</h3>
//                   <p className="text-[#084F67]">{member.jobTitle}</p>
//                 </div>
//               ))}
//             </div>
//           </div>
          
//           {/* Arbitrators Section */}
//           <div
//             className="w-[100%] py-32 bg-white rounded-lg z-100 "  // Reduced margin-top here
//           >
//             <h2
//               className={`text-5xl text-center font-bold leading-[3.5rem] ${
//                 i18n.language === "ar" ? "cairo" : "manrope"
//               }`}
//               style={{ color: "#084F67" }}
//             >
//               {t("OurTeam.header", "Arbitrators")}
//             </h2>
//             <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 mt-12">
//               {arbitrators.map((arbitrator, index) => (
//                 <div key={index} className="flex flex-col items-center">
//                   <div className="p-2 bg-[#084F67] rounded-full">
//                     <img
//                       src={arbitrator.imageUrl}
//                       alt={arbitrator.name}
//                       className="w-32 h-32 rounded-full border-4 border-white shadow-lg"
//                     />
//                   </div>
//                   <h3 className="mt-4 text-xl font-semibold text-[#084F67]">{arbitrator.name}</h3>
//                   <p className="text-[#084F67] text-center">{arbitrator.jobTitle}</p>
//                 </div>
//               ))}
//             </div>
//           </div>
          
//         </div>
//         <div className="w-full flex flex-col items-center mt-24 gap-24">
//           <Footer />
//         </div>
//       </div>
//     </section>
//   );
// };

// export default OurTeam;
import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../UI/Header";
import Footer from "../Footer/Footer";

const OurTeam = () => {
  const { t, i18n } = useTranslation();

  // Fetching team and arbitrators data from translations
  const teamMembers = t("OurTeam.teamMembers", { returnObjects: true });
  const arbitrators = t("OurTeam.arbitrators", { returnObjects: true });

  // Log data to check if all items are loaded correctly
  console.log("Team Members:", teamMembers);
  console.log("Arbitrators:", arbitrators);

  return (
    <section className="w-full bg-white">
      <section className="bg-[#0A4D69] h-[50vh] flex flex-col relative">
        <Header />
      </section>
      <div className="w-[100%] bg-white" style={{ marginTop: "-300px" }}>
        <div className="w-[80%] mx-auto flex flex-col gap-14 relative z-80 mt-20 max-md:mt-14 max-sm:mt-6">
        <h3
          className={`text-6xl text-center text-white uppercase tracking-wide z-100 ${i18n.language === 'ar' ? 'cairo' : 'manrope'} `}
          style={{
            zIndex: "100",
          }}
        >
            {t("Navbar.ourTeam")}
          </h3>
          
          {/* Experts Section */}
          <div className="w-[100%] py-8 bg-white rounded-lg z-100" style={{ marginTop: "-20px" }}>
            <h2
              className={`text-5xl text-center font-bold leading-[3.5rem] ${
                i18n.language === "ar" ? "cairo" : "manrope"
              }`}
              style={{ color: "#084F67" }}
            >
              {t("OurTeam.Experts")}
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 mt-12">
              {teamMembers.map((member) => (
                <div key={member.id} className="flex flex-col items-center">
                  <div className="p-2 bg-[#084F67] rounded-full">
                    <img
                      src={member.imageUrl}
                      alt={t(`Names.${member.id}`)}
                      className="w-32 h-32 rounded-full border-4 border-white shadow-lg"
                    />
                  </div>
                  <h3 className="mt-4 text-xl font-semibold text-[#084F67]">
                    {t(`Names.${member.id}`)}
                  </h3>
                  <p className="text-[#084F67]">{member.jobTitle}</p>
                </div>
              ))}
            </div>
          </div>
          
          {/* Arbitrators Section */}
          <div className="w-[100%] py-8 bg-white rounded-lg z-100">
            <h2
              className={`text-5xl text-center font-bold leading-[3.5rem] ${
                i18n.language === "ar" ? "cairo" : "manrope"
              }`}
              style={{ color: "#084F67" }}
            >
              {t("OurTeam.Arbitrators")}
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 mt-12">
              {arbitrators.map((arbitrator) => (
                <div key={arbitrator.id} className="flex flex-col items-center">
                  <div className="p-2 bg-[#084F67] rounded-full">
                    <img
                      src={arbitrator.imageUrl}
                      alt={t(`Names.${arbitrator.id}`)}
                      className="w-32 h-32 rounded-full border-4 border-white shadow-lg"
                    />
                  </div>
                  <h3 className="mt-4 text-xl font-semibold text-[#084F67]">
                    {t(`Names.${arbitrator.id}`)}
                  </h3>
                  <p className="text-[#084F67] text-center">{arbitrator.jobTitle}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col items-center mt-24 gap-24">
          <Footer />
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
