import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const MAX_TITLE_LENGTH = 60; // Adjust this value to control title truncation
const MAX_DESC_LENGTH = 120; // Adjust this value to control description truncation

const MediaCenterItem = ({ item }) => {
    const { t, i18n } = useTranslation();


    const truncatedTitle =
        item.title.length > MAX_TITLE_LENGTH
            ? item.title.slice(0, MAX_TITLE_LENGTH) + '...'
            : item.title;

    const truncatedDesc =
        item.description.length > MAX_DESC_LENGTH
            ? item.description.slice(0, MAX_DESC_LENGTH) + '...'
            : item.description;

    return (
        <Link to={`/media-centers/${item._id}`}>
            <div className="flex flex-col md:flex-row gap-8 items-center rounded-lg overflow-hidden bg-white shadow-lg h-auto min-h-[200px] ">
                <img
                    src={item.url}
                    className="h-[100%] w-[300px] lg:w-[150px] object-cover p-4"
                    alt={item.title}
                />
                <div className="flex flex-col gap-2 w-full p-2">
                    {/* Title Section */}
                    <p className="text-[#013C4A] font-medium text-sm">
                        {truncatedTitle}

                    </p>

                    {/* Description Section */}
                    <p className="text-[#084F67] text-xs leading-5 font-light tracking-wide">
                        {truncatedDesc}

                    </p>
                    <div>
                        <button
                            className="text-blue-500 text-xs"
                        >
                            {t("Read More")}
                        </button>

                    </div>
                </div>
            </div>
        </Link>
    );
};
export default MediaCenterItem