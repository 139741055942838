import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const SupportModal = ({ onClose, showModal, getTable }) => {
  const { t } = useTranslation();
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [description, setDescription] = useState("");
  const [document, setDocument] = useState("");

  const categories = {
    "Information Technology Services": [
      "selecting exceptional human resources for donors",
      "providing a database for charitable organizations",
      "Providing necessary information for establishing new institutions",
      "suggesting and providing volunteers",
      "Analyzing technical requirements for donors",
      "Financial planning for projects",
      "Providing specialized consultations in impactful and best grants",
      "Providing advice to donors on issues affecting their practices",
    ],
    "Executive and SupportServices": [
      "Embracing a donor institution",
      "Managing grants for donors",
      "Representing the donor in the region",
      "studying grant requests. Assessing charitable organizations",
      "Providing ready-to-grant projects",
      "Developing existing projects and presenting them to donors",
      "Mediating in grant issues between donors and their partners",
      "Managing donor events Registering civil institutions and endowments",
      "Managing social media accounts for donors",
    ],
    "Maximizing Impact": [
      "Conducting research and studies and disseminating their results to donors",
      "Providing specialized knowledge products in grants",
      "Organizing meetings and conferences on grant-related issues",
      "Coordinating partnerships between donors",
      "Attracting and motivating donors",
      "Training grant employees Issuing specialized journals and publications in grants and endowments",
      "Innovating new developmental products in grants",
      "Holding annual enrichment conferences",
      "Providing and suggesting partners (donors + implementers)",
      "Providing information and guidance on best practices in grants",
    ],
  };

  const closeModal = () => {
    setCategory("");
    setSubcategory("");
    setDescription("");
    onClose();
  };

  const handleCategorySelect = (event) => {
    setCategory(event.target.value);
    setSubcategory(""); // Reset subcategory when category changes
  };

  const handleSubcategorySelect = (event) => {
    setSubcategory(event.target.value);
  };

  const handleSubmit = async () => {
    if (!category.trim() || !subcategory.trim() || !description.trim()) {
      alert(t("Please fill all required fields"));
      return;
    }

    const token = localStorage.getItem("token");

    const payload = {
      description,
      document,
      category,
      subCategory: subcategory,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/ticket/createTicket`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      closeModal();
      getTable();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const uploadImage = async (e) => {
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);

      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/upload/singleFileUpload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setDocument(data.fileUrl);
      } catch (error) {
        console.error("File upload error:", error);
      }
    }
  };

  return (
    <Dialog open={showModal} onClose={closeModal} fullWidth maxWidth="sm">
      <DialogTitle>{t("Create Ticket")}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
        <label className='py-2 block text-sm font-medium text-gray-700'>{t('Category')}</label>
          <Select
            displayEmpty
            value={category}
            onChange={handleCategorySelect}
            renderValue={(selected) => (selected ? t(selected) : t("Select Category"))}
          >
            {Object.keys(categories).map((cat) => (
              <MenuItem key={cat} value={cat}>
                {t(cat)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal" disabled={!category}>
        <label className='py-2 block text-sm font-medium text-gray-700'>{t('SubCategory')}</label>

          <Select
            displayEmpty
            value={subcategory}
            onChange={handleSubcategorySelect}
            renderValue={(selected) => (selected ? t(selected) : t("Select SubCategory"))}
          >
            {categories[category]?.map((sub) => (
              <MenuItem key={sub} value={sub}>
                {t(sub)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

       <div className="mt-4"> 
       <label className='py-2 block text-sm font-medium text-gray-700'>{t('Description')}</label>
        <TextField
          fullWidth
          multiline
          rows={3}
          margin="normal"
          label={t("Description")}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
       </div>

        <input
          type="file"
          id="uploadFile"
          style={{ marginTop: "20px" }}
          onChange={uploadImage}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          {t("Submit")}
        </Button>
        <Button onClick={closeModal} variant="outlined">
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SupportModal;
