import React from "react";
import { FaArrowRight } from "react-icons/fa6";
// import {  FaYoutube } from "react-icons/fa";
// import { FaXTwitter, FaFacebook, FaYoutube  } from "react-icons/fa6";
import { IoLogoYoutube, IoLogoFacebook } from "react-icons/io";
import { FaSquareXTwitter, FaSquareYoutube,FaSquareFacebook   } from "react-icons/fa6";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { fetchlocalstorage } from "../../HelperFunctions";

const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="bg-[#0A4D69] w-full rounded-t-[40px] pt-10 md:pt-20 text-white">
      <div className="w-full mx-auto px-4 md:px-0">
        <div className="flex flex-col gap-8 md:gap-12 items-center">
          <div className="flex flex-col gap-2 md:gap-4 items-center justify-center text-center">
            <p className="text-2xl sm:text-4xl md:text-6xl font-bold">
              {t("Footer.heading1")}
            </p>
            <p className="text-2xl sm:text-4xl md:text-6xl font-light">
              {t("Footer.heading2")}
            </p>
          </div>

          <p className="flex flex-col sm:flex-row gap-3 text-sm font-normal text-center sm:text-left">
            {t("Footer.call")}{" "}
            <span className="underline font-bold cursor-pointer">
              0539543215
            </span>{" "}
            {t("Footer.email")}{" "}
            <span className="underline font-bold cursor-pointer">
              info@almoultqa.com
            </span>
          </p>

          <Link to={"/contact"}>
            <button className="bg-[#EDC76F] text-[#013C4A] px-6 py-2 sm:px-8 sm:py-3 rounded-3xl w-fit font-light flex items-center gap-2 text-sm sm:text-base">
              {t("Footer.contactUs")}{" "}
              <span className="text-xs sm:text-sm">
                <FaArrowRight />
              </span>
            </button>
          </Link>
        </div>
      </div>

      <div className="border border-b-0 border-x-0 border-t-[#FFFFFF33] mt-10">
        <div className="w-full md:w-[70%] mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8 md:gap-14 mt-10 px-4 md:px-0">
          {/* left section */}

          <div className="flex flex-col gap-6 items-center md:items-start">
            <img
              src="/images/almoultqaLogo.svg"
              alt="logo"
              className="w-[100px] sm:w-[150px] object-fill"
            />
            <div className="flex flex-col text-sm text-center md:text-left">
              {/* <p className={`text-sm w-60 icon ${i18n.language == "ar" ? "relative" : ""}`}>{t("Footer.address")}</p> */}
              <p
              className={`text-sm w-60 icon ${i18n.language === "ar"
                  ? "relative text-center md:text-right lg:text-right"
                  : "text-center md:text-left lg:text-left"
                }`}
            >
              {t("Footer.address")}
            </p>

            </div>
            <div className="flex gap-4 text-2xl">
              <a
                href="https://www.facebook.com/almoultqaa/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaSquareFacebook  />

              </a>
              <a
                href="https://x.com/almoultqaa"
                target="_blank"
                rel="noopener noreferrer"
              >
               <FaSquareXTwitter />

              </a>
              {/* <a
                href="https://www.example.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin />
              </a> */}
              <a
                href="https://www.youtube.com/@almoultqaa"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaSquareYoutube  />
              </a>
            </div>
            <p
              className={`text-[#e4e3e3cd] text-xs font-light w-72 tracking-wide icon ${i18n.language === "ar"
                  ? "relative text-center md:text-right lg:text-right"
                  : "text-center md:text-left lg:text-left"
                }`}
            >
              {t("Footer.copyright")}
            </p>

          </div>

          {/* right section */}
          <div className="grid grid-cols-3 text-center md:text-start md:grid-cols-3 gap-6 md:gap-12">
            <div className="flex flex-col gap-6">
              <h3
                className={` ${i18n.language == "ar" ? "cairo" : "manrope"
                  } text-white font-semibold text-sm`}
              >
                {t("Footer.USEFUL LINK")}
              </h3>
              <ul className="flex flex-col gap-4 text-[#e4e3e3cd] tracking-wide text-xs">
                <Link to={"/about"}>
                  <li className="underline font-light">{t("Footer.about")}</li>
                </Link>
                <Link to={"/contact"}>
                  <li className="underline font-light">
                    {t("Footer.contactUs")}
                  </li>
                </Link>
                <Link to={"/held-events"}>
                  <li className="underline font-light">{t("Footer.events")}</li>
                </Link>
              </ul>
            </div>
            <div className="flex flex-col gap-6">
              <h3
                className={`${i18n.language == "ar" ? "cairo" : "manrope"
                  } text-white uppercase font-semibold text-sm`}
              >
                {t("Footer.donation")}
              </h3>
              <ul className="flex flex-col gap-4 text-[#e4e3e3cd] tracking-wide text-xs">
                <li className="underline font-light">
                  {t("Footer.How to donate")}
                </li>
                <li className="underline font-light">
                  {t("Footer.Projects creation")}
                </li>
                <li className="underline font-light">
                  {t("Footer.Project Evaluation Criteria")}
                </li>
                <Link to={"/opportunity"}>
                  <li className="underline font-light">
                    {t("Footer.Recent Matured Projects")}
                  </li>
                </Link>
                <Link to={"/faq"}>
                  <li className="underline font-light">{t("Footer.FAQ")}</li>
                </Link>
              </ul>
            </div>
            <div className="flex flex-col gap-6">
              <h3
                className={` ${i18n.language == "ar" ? "cairo" : "manrope"
                  } text-white font-semibold uppercase text-sm `}
              >
                {t("Footer.Legal")}
              </h3>
              <ul className="flex flex-col gap-4 text-[#e4e3e3cd] tracking-wide text-xs">
                <li className="underline font-light">
                  {t("Footer.Terms & Conditions")}
                </li>
                <li className="underline font-light">
                  {t("Footer.Privacy Policy")}
                </li>
                <li className="underline font-light">
                  {t("Footer.Cookies Policy")}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-[30px] border-0 mt-8 bg-gradient-to-b from-[#0b4d68] to-[#068293]"></div>
    </div>
  );
};

export default Footer;