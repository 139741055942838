import React, { useEffect } from "react";
import { useTable } from "react-table";
import { fetchlocalstorage } from "../../HelperFunctions";
import { useTranslation } from "react-i18next";

const MyTable = ({ columns, data }) => {
  const { t, i18n } = useTranslation();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <div className="  h-full  mt-4  overflow-x-auto " >
      <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    borderBottom: 'solid 3px ',
                    //   background: 'aliceblue',
                    color: 'black',
                    fontWeight: 'bold',
                    padding: '10px',
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: '10px',
                        border: 'solid 1px gray',
                        // background: 'papayawhip',
                        maxWidth: '400px', // Set max width for the column
                        wordWrap: 'break-word', // Enable word wrapping
                      }}
                    >
                      {t(cell?.value)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MyTable;
