import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { fetchlocalstorage, isAuth, logOut } from "../../HelperFunctions";
import { getProfile, signupLogedInConsultantApi } from "../../Api";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userData, setUserData] = useState({});
  const { t, i18n } = useTranslation();
  const modalRef = useRef(null);
  const dropdownRefs = useRef([]);

  function toggleDropdown(status) {
    setIsDropdownOpen(status);
  }
  let getUserInfo = localStorage.getItem("userDetails");
  getUserInfo = JSON.parse(getUserInfo);

  let getAuth = isAuth();

  const toggleLanguage = () => {
    const newLanguage = i18n.language === "en" ? "ar" : "en";
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  };
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      toggleDropdown(false);
    }
  };

  async function loginAsConsultant(id) {
    try {
      let res = await signupLogedInConsultantApi(id, { isConsultant: true });
      localStorage.setItem("userDetails", JSON.stringify(res?.data?.user));
      window.location.reload();
    } catch (error) {
      toast.error(t(error?.response?.data?.message), { id: "err501" });
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // console.log(JSON.parse(getUserInfo))
  return (
    <nav className="bg-white shadow ">
      <div className="max-w-8xl mx-auto my-1 xs:px-1 sm:px-6 lg:px-7">
        <div className="flex justify-between h-16 items-center">
          {/* Left-aligned logo and text */}
          {/* <div className="flex items-center"> */}
          <Link to={"/"} className="flex items-center">
            <img
              src="/login_image/login.svg"
              alt="Logo"
              className={`h-8 w-12 mr-2 ${
                fetchlocalstorage("language") == "ar" ? "ml-2 w-14 h-10" : ""
              }`}
            />
            <span className="xs:hidden sm:block  w-[100px]">Al-moultqa</span>
            <Link to="/">
              <img
                src="/login_image/logo2.png"
                alt=""
                className="mb-2 w-full mr-2"
                style={{ width: "100px", height: "40px", objectFit: "contain" }}
              />{" "}
            </Link>
          </Link>
          {/* </div> */}

          {/* Center-aligned navigation links */}
          <div className="hidden xl:flex sm:space-x-4">
            <Link
              to="/"
              className="text-gray-700 hover:text-gray-900 px-3 no-underline py-2 rounded-md text-sm font-medium"
            >
              {t(`Home`)}
            </Link>
            <Link
              to="/about"
              className="text-gray-700 hover:text-gray-900 px-3 py-2 no-underline rounded-md text-sm font-medium"
            >
              {t(`About`)}
            </Link>
            <Link
              to="/opportunity"
              className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md no-underline text-sm font-medium"
            >
              {t(`Giving Opportunities`)}
            </Link>
            <Link
              to="/held-events"
              className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md no-underline text-sm font-medium"
            >
              {t(`Events`)}
            </Link>
            <Link
              to="/faq"
              className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm no-underline font-medium"
            >
              {t(`Faq`)}
            </Link>
            {/* <Link
              to="/consultant"
              className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm no-underline font-medium"
            >
              {t(`Consultant`)}
            </Link> */}

            {/* <a href="#" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">News</a> */}
            <Link
              to="/contact"
              className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm no-underline font-medium"
            >
              {t(`Contact`)}
            </Link>
          </div>

          {/* Right-aligned button */}
          <div className="App ">
            {/* <div className="cursor-pointer flex justify-center self-stretch px-6 py-1.5 max-md:px-5"> */}
            <select
              onChange={toggleLanguage}
              value={i18n.language}
              className={`px-1 py-2    rounded text-black cursor-pointer> ${
                fetchlocalstorage("language") == "ar" ? "ml-2" : ""
              }`}
            >
              <option value="en">English</option>
              <option value="ar">العربية</option>
            </select>
            {/* </div> */}

            {/* <button className="button-bg-transition">
        Make Donation
      </button> */}
            <div
              ref={modalRef}
              className={`hamburger-menu menu-bar relative info-bar realtive d-inline-block  width-[200px]  ${
                fetchlocalstorage("language") == "ar" ? "mr-2" : "ml-5"
              }`
            
            }

         
            >
              <button
                onClick={() => toggleDropdown(!isDropdownOpen)}
                className="hamburger-btn open-mobile-menu"
              >
                <i className="fal fa-bars"></i>
              </button>
              {isDropdownOpen && (
                <div
                  className={`dropdown-menu absolute ${
                    fetchlocalstorage("language") === "en"
                      ? "  right-[14px]"
                      : "left-[3px] w-[200px]"
                  }`}
                >
                  <span className="xs:block xl:hidden">
                    <Link
                      to="/"
                      className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                    >
                      {t(`Home`)}
                    </Link>
                    <Link
                      to="/about"
                      className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                    >
                      {t(`About`)}
                    </Link>
                    <Link
                      to="/causes"
                      className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                    >
                      {t(`Projects`)}
                    </Link>
                    <Link
                      to="/held-events"
                      className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                    >
                      {t(`Events`)}
                    </Link>
                    {/* <Link to="/consultant" className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t(`Consultant`)}</Link> */}
                    <Link
                      to="/faq"
                      className="text-gray-700    hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                    >
                      {t(`Faq`)}
                    </Link>

                    {/* <a href="#" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">News</a> */}
                    <Link
                      to="/contact"
                      className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                    >
                      {t(`Contact`)}
                    </Link>
                  </span>

                  {getAuth ? (
                    ""
                  ) : (
                    <Link
                      to="/login"
                      className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                    >
                      {t(`Login Donar`)}
                    </Link>
                  )}
                  {getAuth ? (
                    ""
                  ) : (
                    <Link
                      to="/signin-by-org"
                      className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                    >
                      {t(`Login organization`)}
                    </Link>
                  )}
                  {getAuth ? (
                    getUserInfo?.role == "CHARITY" ? (
                      getUserInfo.isProfileCreated ? (
                        <Link
                          to="/list-all-projects"
                          className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                        >
                          {t(`Project List`)}
                        </Link>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {/* {getAuth?JSON.parse(getUserInfo)?.role=="CHARITY"? <Link to='/organization-profile'>My profile</Link>: <Link to='/donor-profile'>My profile</Link>:""}
                   */}

                  {getAuth ? (
                    //Role == Charity && profile is created show charity: my profile, else redirect to charity creation form
                    getUserInfo?.role == "CHARITY" ? (
                      //For Charity ,redirect to charity
                      getUserInfo.isProfileCreated ? (
                        <Link
                          to="/organization-profile"
                          className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                        >
                          {t(`My Profile`)}
                        </Link>
                      ) : (
                        <Link
                          to="/charity"
                          className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                        >
                          {t(`My Profile`)}
                        </Link>
                      )
                    ) : (
                      //For Donor, redirect to donation
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {getAuth ? (
                    //Role == DONOT && profile is created show donor: my profile, else redirect to donor creation form
                    getUserInfo?.role == "DONOR" ? (
                      getUserInfo.isProfileCreated ? (
                        <Link
                          to="/donor-profile"
                          className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                        >
                          {t(`My Profile`)}
                        </Link>
                      ) : (
                        <Link
                          to="/donor"
                          className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                        >
                          {t(`My Profile`)}
                        </Link>
                      )
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {getAuth ? (
                    <Link to="/my-bookings">{t(`My Booking`)}</Link>
                  ) : (
                    ""
                  )}
                  {getAuth ? (
                    getUserInfo.role == "CONSULTANT" ||
                    getUserInfo.isConsultant ? (
                      <Link to="/my-availability">{t(`My Availability`)}</Link>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {getAuth ? (
                    getUserInfo.role == "CONSULTANT" ||
                    getUserInfo.isConsultant ? (
                      <Link to="/consultant-profile">{t(`My Profile`)}</Link>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {getAuth ? (
                    getUserInfo.isProfileCreated ? (
                      <Link to="/chat">{t(`Chats`)}</Link>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {getAuth ? (
                    getUserInfo.isProfileCreated &&
                    getUserInfo.role == "CHARITY" ? (
                      <Link
                        to="/get-all-workshop"
                        className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                      >
                        {t(`My Workshop`)}
                      </Link>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {getAuth ? (
                    getUserInfo.isProfileCreated &&
                    getUserInfo.role == "DONOR" ? (
                      <Link
                        to="/my-donations"
                        className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                      >
                        {t(`My Donations`)}
                      </Link>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {getAuth ? (
                    getUserInfo.role == "CHARITY" ||
                    getUserInfo.isConsultant ? (
                      <Link to="/my-analytics">{t(`My Analytics`)}</Link>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {/* {getAuth ?  getUserInfo.role != "CONSULTANT"&& !getUserInfo.isConsultant?<Link onClick={()=>loginAsConsultant(getUserInfo._id)} className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{t(`Signup Consultant`)}</Link> : "":""} */}

                  {/* {!getAuth ? <Link to='/login-consultant' className='text-gray-700   hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium'>{t(`Login Consultant`)}</Link> : ""} */}

                  {getAuth ? (
                    <Link
                      to=""
                      onClick={() => logOut()}
                      className="text-gray-700  hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                    >
                      {t("Log Out")}
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
