import React, { memo, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../../Nav_Form/Navbar';
import { useFormik } from 'formik';
import { signUpByOrgInitialValue } from '../../../Validations/Organization/initialValues';
import { signupValidationForOrg } from '../../../Validations/Organization';
import toast from 'react-hot-toast';
import { callDomainForOrg, signupOrgApi } from '../../../Api';
import * as yup from "yup";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import axios from 'axios';

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false); // State for terms acceptance
  const { t } = useTranslation();
  const [domain, setDomain] = useState([]);

  const location = useLocation();
  const { pathname } = location;

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      domain: [],
      email: "",
      password: "",
      bio: "",
      profileImage: "",
      role: "CONSULTANT"
    },
    validationSchema: yup.object({
      name: yup.string().required(<>{t('Name is a required field')}</>),
      domain: yup.array()
        .min(1, 'Select at least one option')
        .required('Domain is required'),
      email: yup.string().email('Invalid email').required(<>{t('Email is a required field')}</>),
      password: yup.string().min(6, <>{t('Password must be at least 6 characters')}</>).required(<>{t('Password')}</>),
      bio: yup.string().required(<>{t('Bio')}</>),
      profileImage: yup.string().required(<>{t('Profile Image')}</>)
    }),
    onSubmit: async (values) => {
      try {
        values.domain = formik.values?.domain && formik.values?.domain?.map((item) => item.value);

        const res = await signupOrgApi(values);
        if (res.status === 200 || res.status === 201) {
          if (res?.data?.token === false) {
            toast(t(res?.data?.message), { icon: "⚠️", id: "1102" });
            navigate("/login", { replace: true });

          } else {
            toast.success(t(res?.data?.message), { id: "001" });
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("userDetails", JSON.stringify(res.data.user));

            navigate("/my-availability", { replace: true });
          }
        }
      } catch (error) {
        toast.error(t(error?.response?.data?.message), { id: "err501" });
      }
    },
  });

  async function callDomainType(params) {
    try {
      const data = await callDomainForOrg();
      const newData = data?.data?.domains.map(domain => ({
        value: domain?._id,
        label: domain?.name
      }));
      setDomain(newData);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    callDomainType();
  }, []);

  const uploadImage = async (e, fieldType) => {
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);

      let data = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/upload/singleFileUpload`, formData, {
        headers: {
          "Content-Type": 'multipart/form-data',
        },
      });
      formik.setFieldValue(fieldType, data.data.fileUrl);
    }
  };

  return (
    <div className='relative'>
      <Navbar />
      <div className='flex justify-center mt-2 h-screen '>
        <form onSubmit={formik.handleSubmit}>
          <div className='flex flex-col border w-[500px] shadow-lg rounded-md px-8 py-10'>
            <div className='flex items-center mb-4'>
              <Link><img src='/login_image/login.svg' alt='' className='mb-4 mr-2' /></Link>
              <span className='text-xl text-green-900'>{t('Al-moultqa')}</span>
            </div>

            <h1 className='text-xl font-normal mb-4 my-3'>{t('Welcome to Al-moultqa!')} 👋</h1>
            <p className='mb-4'>يرجى تسجيل الدخول إلى حسابك وابدأ المغامرة</p>
            <div className='flex flex-col mb-4 w-full'>
              <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">{t('Name')}</label>
                <input
                  type='text'
                  placeholder={t('Please enter your name')}
                  {...formik.getFieldProps('name')}
                  className='border border-gray-300 rounded-md p-2 mb-2 w-full'
                />
                {formik.touched.name && formik.errors.name ? (
                  <p className="text-red-500">{formik.errors.name}</p>
                ) : null}
              </div>
              <div className="mb-4">
                <label htmlFor="domain" className="block text-sm font-medium text-gray-700">{t('Consultancy domain')}</label>
                <Select
                  id="domain"
                  name="domain"
                  isMulti
                  options={domain}
                  className="mt-1 block w-full"
                  classNamePrefix="react-select"
                  value={formik.values.domain}
                  onChange={value => formik.setFieldValue('domain', value)}
                />
                {formik.touched.domain && formik.errors.domain ? (
                  <p className="text-red-500">{formik.errors.domain}</p>
                ) : null}
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">{t('Email')}</label>
                <input
                  type='email'
                  placeholder={t('Please enter your email')}
                  {...formik.getFieldProps('email')}
                  className='border border-gray-300 rounded-md p-2 mb-2 w-full'
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="text-red-500">{formik.errors.email}</p>
                ) : null}
              </div>
              <div className="mb-4">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">{t('Password')}</label>
                <input
                  name='password'
                  type='password'
                  placeholder={t('Please enter your password')}
                  {...formik.getFieldProps('password')}
                  className='border border-gray-300 rounded-md p-2 mb-2 w-full'
                />
                {formik.touched.password && formik.errors.password ? (
                  <p className="text-red-500">{formik.errors.password}</p>
                ) : null}
              </div>
              <div className="mb-4">
                <label htmlFor="bio" className="block text-sm font-medium text-gray-700">{t('Bio')}</label>
                <input
                  type='text'
                  placeholder={t('Please enter bio')}
                  {...formik.getFieldProps('bio')}
                  className='border border-gray-300 rounded-md p-2 mb-2 w-full'
                />
                {formik.touched.bio && formik.errors.bio ? (
                  <p className="text-red-500">{formik.errors.bio}</p>
                ) : null}
              </div>
              <div className="mb-4">
                <label htmlFor="profileImage" className="block text-sm font-medium text-gray-700">{t('Profile Image')}</label>
                <input
                  type='file'
                  className='border border-gray-300 rounded-md p-2 mb-2 w-full'
                  onChange={(e) => uploadImage(e, "profileImage")}
                />
                {formik.touched.profileImage && formik.errors.profileImage ? (
                  <p className="text-red-500">{formik.errors.profileImage}</p>
                ) : null}
              </div>
              <div className='flex items-center mb-2'>
                <input
                  type='checkbox'
                  checked={rememberMe}
                  onChange={e => setRememberMe(e.target.checked)}
                  className='mr-2'
                />
                <label htmlFor='remember-me'>{t('Remember Me')}</label>
              </div>
              <div className='flex items-center mb-4'>
                <input
                  type='checkbox'
                  checked={termsAccepted}
                  onChange={e => setTermsAccepted(e.target.checked)}
                  className='mr-2'
                />
                <label htmlFor='terms'>{t('I accept the')} <button
                    type='button'
                    className='text-blue-500 underline ml-1'
                    onClick={() => window.open('/terms_condition.pdf', '_blank')}
                  >
                    {t('Terms and Conditions')}
                  </button></label>
              </div>
            </div>
            <button
              className={`bg-green-500 text-white px-4 py-2 rounded-md w-full ${!termsAccepted ? 'opacity-50 cursor-not-allowed' : ''}`}
              type='submit'
              disabled={!termsAccepted}
            >
              {t('Sign Up')}
            </button>
          </div>
        </form>
      </div>
      <img
        src='/login_image/login_image2.svg'
        alt=''
        className='absolute top-6 right-[30px] mt-8'
      />
    </div>
  );
}

export default SignUp;
