import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Components/Nav_Form/Navbar";
import { FaPen } from "react-icons/fa";
import ProjectInfo from "../Components/ProjectDetails/ProjectInfo";
import ReportSection from "../Components/ProjectDetails/ReportSection";
import DemoProjectInfo from "../Components/ProjectDetails/DemoProjectInfo";

import { getProjectDetailApi, getProjectComments } from "../Api";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { fetchlocalstorage } from "../HelperFunctions";

// ** Third Party Imports
import * as XLSX from "xlsx";

import { flattenProjectComments } from "../utils/FlattenedData";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/UI/Header";

const DemoProjectDetail = () => {
  const location = useLocation();
  // console.log(location)
  const state = location.state;
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [projectDetail, setProjectDetail] = useState(null);
  const [projectComments, setprojectComments] = useState([]);

  // const projectID  = projectDetail?._id

  async function getProjectDetail() {
    let { data } = await getProjectDetailApi(state?.id);
    // console.log(data)
    // console.log("11111 ",data)
    setProjectDetail(data?.data);

    console.log("project datels - getting details only api ->", data?.data);

    // After fetching project details, fetch the project comments
    await getAllProjectComments(data?.data?._id);
  }

  const getAllProjectComments = async (projectID) => {
    try {
      if (!projectID) {
        console.error("Project ID is missing");
        return;
      }

      // const { data } = await callAxios('get', `projectcomment/getAllProjectComments/${existingData?._id}`)
      let { data } = await getProjectComments(projectID);
      // console.log("project details inside comments -> ", projectID)
      console.log("project comments -> ", data);

      setprojectComments(data?.data);
    } catch (error) {
      console.log(error);
      toast.error(
        t(error?.response?.data?.message) || t("Something went wrong")
      );
    }
  };

  const [notes, setNotes] = useState("");
  const [recommendation, setRecommendation] = useState("");
  const [selectedInput, setSelectedInput] = useState("");
  const [isViewNote, setIsViewNote] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
    setIsViewNote(null);
  };
  const handleModalOpen = () => {
    setModalOpen(true);
    if (!isViewNote) {
      setNotes(projectComments[0]?.[selectedInput]?.note ?? "");
      setRecommendation(
        projectComments[0]?.[selectedInput]?.recommendation ?? ""
      );
    }
  };
  const handleViewNotes = () => {
    handleClose();
    setIsViewNote(true);
    handleModalOpen();
  };
  const handleAddNotes = () => {
    handleClose();
    setIsViewNote(false);
    handleModalOpen();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log('projectDetail', projectDetail);
  // console.log('projects comments', projectComments)

  // useEffect(() => {
  //     getProjectDetail()
  //     getAllProjectComments()
  // }, [])

  useEffect(() => {
    getProjectDetail();
  }, []);

  const handleDownload = async () => {
    try {
      // Convert JSON data to Excel workbook
      const flattenedData = flattenProjectComments(projectComments);
      const worksheet = XLSX.utils.json_to_sheet(flattenedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Calculate the maximum width for each column
      const columnWidths = Object.keys(flattenedData[0]).map(
        (key, colIndex) => {
          const maxLength = flattenedData.reduce((max, row) => {
            const cellValue = row[key] ? row[key].toString() : "";
            return Math.max(max, cellValue.length);
          }, key.length); // include header length

          return { wch: maxLength + 2 }; // add some padding
        }
      );

      // Set the column widths in the worksheet
      worksheet["!cols"] = columnWidths;

      // Convert workbook to binary data
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from binary data
      const excelBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = URL.createObjectURL(excelBlob);

      // Set the download attribute and click the link to trigger the download
      link.download = "project_comments.xlsx";
      link.click();
    } catch (error) {
      console.error("Error downloading data:", error);
      // Handle errors if any
    }
  };

  return (
    <>
      {/* <Navbar /> */}

      <section className="bg-[#0A4D69] h-[11vh] flex flex-col z-40 relative">
        <Header />
      </section>

      <div className="sm:p-8 md:p-8 lg:p-16">
        <div>
          <div className="single_donation_content border p-2">
            <div className="flex justify-between items-center w-full py-2">
              <h4 className="section_title mb-5 text-xl">
                {t(`Project Details`)}
              </h4>
              {projectComments.length > 0 ? (
                <div
                  onClick={handleDownload}
                  className="border border-green-500 rounded-md px-6 py-1 text-green-500 cursor-pointer hover:bg-green-50 transition-all duration-200 "
                >
                  {t("Download")}
                </div>
              ) : (
                ""
              )}
            </div>
            {/* mid */}
            <div className="flex  ">
              <div className="hidden rounded w-[80.556px] h-[80.556px] mr-[26px] border-none sm:block w-24 p-2 border-2  bg-project_bg_image_color text-white text-lg ">
                <div
                  className=" border-2 w-full h-full bg-project_image_color text-white text-lg flex justify-center item-center"
                  style={{
                    borderRadius: "6px",
                    border: "none",
                  }}
                >
                  <h3 className="text-[3rem] text-white">
                    {projectDetail?.projectName?.charAt(0).toUpperCase()}
                  </h3>
                </div>
              </div>

              <div className="w-3/4">
                <div>
                  <span
                    className={`font-bold text-black  ${
                      fetchlocalstorage("language") == "ar" ? "ml-2" : ""
                    }`}
                  >
                    {t(`Project Name`)}:
                  </span>{" "}
                  <span>{projectDetail?.projectName}</span>
                </div>

                <div>
                  {" "}
                  <span>
                    <span
                      className={`font-bold text-black  ${
                        fetchlocalstorage("language") == "ar" ? "ml-2" : ""
                      }`}
                    >
                      {t(`Project Domain`)}:{" "}
                    </span>
                    {state?.name}
                  </span>
                </div>

                <div className="flex item-center  ">
                  <p
                    className={` text-black  font-bold  mr-2 ${
                      fetchlocalstorage("language") == "ar" ? "ml-2" : ""
                    }`}
                  >
                    {t(`Status`)}:{" "}
                  </p>
                  <p>{t(projectDetail?.status)}</p>
                </div>
              </div>
              {projectDetail?.status == "DRAFT" ||
              projectDetail?.status == "RESENT" ? (
                <div
                  className="flex items-center justify-end flex-grow "
                  onClick={() =>
                    navigate("/update-project", { state: { projectDetail } })
                  }
                >
                  {" "}
                  {/* Edit button at end */}
                  <span className="flex bg-gray-300 px-4 py-2 text-black	justify-center align-center   rounded  cursor-pointer">
                    <button className="ml-auto mr-3">{t(`Edit`)}</button>{" "}
                    {/* ml-auto for margin-left: auto */}
                    <span>
                      <FaPen />
                    </span>
                  </span>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            {/* third div */}
            <div className=" flex  justify-center align-center">
              <div>
                <div className="feature_progress_wrapper mb-25 mt-35">
                  <div className="progress feature_progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${
                          (projectDetail?.raised /
                            projectDetail?.financialDetailsTotal) *
                          100
                        }%`,
                      }}
                    ></div>
                  </div>
                </div>
                <div className="single_cause_meta mb-20">
                  <div className="single_meta feature_meta d-inline-block">
                    <span className="meta_text red_clr">
                      <i className="fal fa-reply"></i> {t(`To Go`)}
                    </span>
                    <span className="meta_price red_clr">
                      ﷼
                      {Math.floor(
                        projectDetail?.totalAfterVat - projectDetail?.raised
                      ) || 0}
                    </span>
                  </div>
                  <div className="single_meta feature_meta feature_border d-inline-block">
                    <span className="meta_text red_clr">
                      <i className="fal fa-users"></i> {t(`Raised`)}
                    </span>
                    <span className="meta_price red_clr">
                      ﷼{projectDetail?.raised || 0}
                    </span>
                  </div>
                  <div className="single_meta feature_meta feature_border d-inline-block">
                    <span className="meta_text red_clr">
                      <i className="fal fa-globe"></i> {t(`Goal`)}
                    </span>
                    <span className="meta_price red_clr">
                      ﷼{projectDetail?.totalAfterVat}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {projectDetail?.status == "INITIAL_NOTES" ? (
          <div className=" border p-2 mt-2">
            <h4 className="section_title text-4xl">{t(`Remark`)}</h4>
            {projectDetail?.remark !== "" ? (
              <div>{projectDetail?.remark}</div>
            ) : (
              <div>{t(`No Remark`)}</div>
            )}
          </div>
        ) : (
          ""
        )}

        <DemoProjectInfo
          projectComments={projectComments}
          projectDetail={projectDetail}
          name={state?.name}
        />
      </div>

      <Footer />
    </>
  );
};

export default DemoProjectDetail;
