import React, { useState, useEffect, useRef } from "react";
import { db, fb_messages } from "../../firebase";
import {
  collection,
  onSnapshot,
  query,
  where,
  and,
  addDoc,
  updateDoc,
  getDocs,
} from "@firebase/firestore";

const ChatLog = ({ selectedChat, setNewUsers }) => {
  const [allMessages, setAllMessages] = useState([]);
  const getUserInfo = JSON.parse(localStorage.getItem("userDetails"));
  const userId = getUserInfo._id;
  const chatEndRef = useRef(null);

  useEffect(() => {
    if (Object.keys(selectedChat).length === 0) return;

    const q = query(
      collection(db, fb_messages),
      and(
        where("sender", "in", [userId, selectedChat?._id]),
        where("receiver", "in", [userId, selectedChat?._id])
      )
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      let messages = snapshot.docs.map((doc) => ({
        ...doc.data(),
        timestamp: new Date(doc.data().timestamp.seconds * 1000),
      }));

      const sortedMessages = messages.sort((a, b) => a.timestamp - b.timestamp);
      setAllMessages(sortedMessages);
      scrollToBottom();
    });

    updateUserNewMessage();

    return () => {
      unsubscribe();
    };
  }, [selectedChat]);

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const updateUserNewMessage = async () => {
    if (!selectedChat) return;

    try {
      const userRef = collection(db, "users");
      const userQuery = query(
        userRef,
        and(where("newMessage", "==", true), where("receiver", "==", userId))
      );
      const userSnapshot = await getDocs(userQuery);

      if (userSnapshot.empty) {
        await addDoc(userRef, {
          sender: userId,
          receiver: selectedChat?._id,
          timestamp: new Date(),
          newMessage: false,
        });
      } else {
        const userDoc = userSnapshot.docs[0];
        await updateDoc(userDoc.ref, {
          newMessage: false,
        });
      }

      if (selectedChat?.newMessage) {
        setNewUsers((prev) =>
          prev.map((el) => {
            if (el?.id === selectedChat?.id) {
              return { ...el, newMessage: false };
            }
            return el;
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderChats = () => {
    return allMessages.map((message, index) => {
      const isSender = message.sender === userId;
      const name = isSender ? getUserInfo?.name : selectedChat?.name;

      return (
        <div
          key={index}
          className={`flex ${isSender ? "justify-end" : "justify-start"} mb-4`}
        >
          <div
            className={`${
              isSender
                ? "bg-blue-400 mr-2 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl"
                : "bg-gray-400 ml-2 rounded-br-3xl rounded-tr-3xl rounded-tl-xl"
            } py-3 px-4 text-white`}
          >
            {message.message}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-auto">
        {renderChats()}
        <div ref={chatEndRef} />
      </div>
    </div>
  );
};

export default ChatLog;
