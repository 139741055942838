import React, { useEffect, useState } from 'react';
import Aboutfootertoparea2 from '../Aboutfootertoparea2';
import FullCalendar from '@fullcalendar/react';

import dayGridPlugin from '@fullcalendar/daygrid';
import SlotModal from "../../Modal/SlotModal"
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { useLocation } from 'react-router';
import { getAllAvailabilityApi, getAllAvailabilitySlotsApi } from '../../Api';
import moment from 'moment-hijri';
import HijriCalendar from './HijriCalendar';
import { useTranslation } from 'react-i18next';
// import moment from "moment-hijri";
// import 'moment/locale/ar' 
import '@fullcalendar/core/locales/ar';  // Import Arabic locale for FullCalendar
import Header from '../UI/Header';


// import "@deskpro/react-datepicker-hijri/dist/react-datepicker.css";


// moment.locale('ar'); 


const ConsultantDetail = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [slots, setSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [availability, setAvailability] = useState([])
  const { state } = useLocation()
  const {t}=useTranslation();
  const todayHijri = moment().format('iYYYY-iMM-iDD');
  // console.log(todayHijri)

  //Fetch slots for perticular day
  const fetchSlots = async (id, date) => {
    //   const response = await fetch(`YOUR_API_ENDPOINT/${date}`);
    //   const data = await response.json();
    const res = await getAllAvailabilitySlotsApi(id, date)
    // console.log(res)
    setSlots(res?.data?.data);

  };
  //Handle clicked day
  const handleDateClick = async (arg) => {
    const inputDate = new Date(arg.dateStr);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(inputDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`
    console.log(formattedDate)

    const clickedDay = inputDate.toLocaleString('en-US', { weekday: 'long' });
    // if (availability.includes(clickedDay) && inputDate >= new Date().setHours(0, 0, 0, 0)  ) {
    if (availability.includes(clickedDay)) {
      // Do nothing if the clicked day is in the array of blocked days
      // return;
      await fetchSlots(state?.consultant?._id, formattedDate);
      setSelectedDate(formattedDate)
      setIsModalOpen(true);
    }
    // setSelectedDate(date);
  };

  //background gray for not relevant date
  const dayCellClassNames = (date) => {
    const dayName = date.date.toLocaleDateString('en-us', { weekday: 'long' });
    console.log(date.date)
    // if (availability.includes(dayName) && date.date >= new Date().setHours(0, 0, 0, 0)) {
    if (availability.includes(dayName)) {
      return [];
    } else {
      return ['blocked-day'];
    }
  };

  const getAvailableConsultantDays = async () => {
    try {
      let data = await getAllAvailabilityApi(state?.consultant?._id)

      let newData = Object.keys(data?.data?.data?.availability)
      console.log('newData avalable days', newData)

      setAvailability(newData)

    } catch (error) {
      console.log(error)
    }

  }

  useEffect(() => {
    getAvailableConsultantDays()

  }, [])


  return (
    <div>
       <section className="bg-[#0A4D69] h-[11vh] flex flex-col z-40 relative">
                <Header />
            </section>
      <div className='p-5'>
        <div className="w-full flex justify-between items-center">
          <div className="w-full flex flex-col gap-2">
            <h6 className="manrope text-2xl font-semibold leading-8 tracking-wide text-tertiary">
              {t('Calendar')}
            </h6>

          </div>
        </div>
        {/* <div className="w-full bg-white rounded-lg shadow p-4 flex flex-col gap-4">
        <FullCalendar 
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
         locale="ar"
         initialDate={moment().format('iYYYY-iMM-iDD')}
         //  timeZone="Asia/Riyadh"
         dateClick={handleDateClick} 
        editable={true}// Add dateClick handler
        dayCellClassNames={dayCellClassNames}
        datesSet={(dateInfo) => {
          // Update the calendar to show Hijri dates
          const hijriStart = moment(dateInfo.start).format('iYYYY-iMM-iDD');
          const hijriEnd = moment(dateInfo.end).format('iYYYY-iMM-iDD');
          console.log(`Showing events from ${hijriStart} to ${hijriEnd}`);
        }}
        dayHeaderContent={(args) => {
          // Show Hijri day names
          return moment(args.date).format('dddd');
        }}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false
        }}
        eventContent={(arg) => {
          const hijriDate = moment(arg.event.start).format('iYYYY-iMM-iDD');
          return (
            <>
              <b>{arg.event.title}</b>
              <i>{hijriDate}</i>
            </>
          );
        }}
        />
      </div> */}

        <HijriCalendar />

        {/* {isModalOpen&& <SlotModal  onClose={setIsModalOpen} slots={slots} id={state?.consultant?._id} date={selectedDate}/>} */}
      </div>
      <Aboutfootertoparea2 />
    </div>

  );
};

export default ConsultantDetail;
