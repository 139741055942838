import React from "react";
import { useTranslation } from "react-i18next";
import { FaClipboardList } from "react-icons/fa";


const ScoreCard = ({ points, title, description }) => (
  <div className="flex flex-col leading-7 max-md:mt-10 max-md:max-w-full">
    <div className="flex gap-0 uppercase max-md:flex-wrap">
      <div className="justify-center items-center px-2.5 text-2xl font-semibold text-center text-sky-900 bg-white border-sky-900 border-solid border-[3px] h-[98px] rounded-[49px] w-[98px] max-md:px-5">
        {points} <span className="text-sm">points</span>
      </div>
      <div className="grow justify-center self-start p-2.5 mt-1.5 text-lg font-medium text-white bg-sky-900 rounded-lg w-fit max-md:pr-7 max-md:pl-5 max-md:max-w-full">
        {title}
      </div>
    </div>
    <div className="self-end mt-6 mr-5 text-lg font-light text-slate-500 max-md:mr-2.5 max-md:max-w-full">
      {description}
    </div>
  </div>
);

const ProjectEvaluation = () => {
  const { t , i18n } = useTranslation();

  const scoreCards = [
    {
      points: "10",
      title: `${t("About1.val2title")}`,
      description: `${t("About1.eval2desc")}`,
    },
    {
      points: "05",
      title: `${t("About1.eval1title")}`,
      description: `${t("About1.eval1desc")}`,
    },
    {
      points: "15",
      title: "Project Objectives",
      description:
        "The more the goals focus on the final beneficiary, the higher the chance of earning the full 10 points",
    },
    {
      points: "10",
      title: "The organization's and team's ability to execute the project",
      description:
        "Despite the project's quality and excellence, the organization or the team may not have the capability to execute it. Therefore, the 15 points are equally divided between the organization and the team.",
    },
    {
      points: "05",
      title: "Reasons for Choosing the Project",
      description:
        "The more the reasons stem from a genuine need based on accurate and precise information, the higher the score that can be achieved.",
    },
    {
      points: "05",
      title: "The Realism of Expected Project Outcomes",
      description: "They should be specific, tangible, and of high quality.",
    },
    {
      points: "05",
      title: "Reasons for Choosing the Project",
      description: "They should be specific, tangible, and of high quality.",
    },
    {
      points: "10",
      title: "The Project's Relevance to the Entity's Specialization",
      description:
        "This project directly aligns with the entity's specialization.",
    },
    {
      points: "10",
      title: "How realistic is the expected impact of project implementation?",
      description:
        "The Realism of the Expected Impact from Project Implementation",
    },
    {
      points: "38",
      title: "The Project's Relevance to the Entity's Specialization",
      description:
        "This project directly aligns with the entity's specialization",
    },
    {
      points: "10",
      title: "The extent of the targeted group's need for the project",
      description:
        "The more the project document uses a scientific approach based on reliable, up-to-date, accurate information about the targeted group's needs, and the more urgent these needs are, the project deserves a full score based on that.",
    },
    {
      points: "15",
      title: "Level of Creativity and Innovation Used in the Project",
      description:
        "Since it is a differentiating criterion, it can be measured by comparing it with competing projects in the same field only.",
    },
    {
      points: "10",
      title: "The extent of the targeted group's need for the project",
      description: "",
    },
    {
      points: "15",
      title: "Presence of a Mechanism for Project Measurement and Evaluation",
      description:
        "The Clarity, Ease of Measurement, and Accuracy of the Mechanism, each deserving 10 points",
    },
    {
      points: "20",
      title: "The Financial Cost of the Project",
      description:
        "The more detailed, clear, and accurately calculated the financial cost of the project, the greater the project's chance of earning the full score.",
    },
    {
      points: "05",
      title: "Achieving Partnerships and Integration",
      description:
        "Each partnership or integration deserves 10 points, provided that the scope of work of each partner is clear, along with their authorities and responsibilities.",
    },
    {
      points: "03",
      title: "Feasibility of Execution even without Financial Support",
      description:
        "Project segmentation into temporal, geographical, technical stages, and others)",
    },
    {
      points: "02",
      title: "Presence of a Project Manager",
      description: "Manages the project and monitors expenses",
    },
    {
      points: "07",
      title:
        "Governance according to the National Center for Non-Profit Sector",
      description:
        "Project segmentation into temporal, geographical, technical stages, and others)",
    },
   
  ];

  return (
    <section className="flex flex-col gap-8 w-full max-w-[80%]">
          {/* <img src={evaluation_icon} alt="" height={20} width={20}/> */}
      <div className="flex gap-2.5 pr-5 text-4xl tracking-wide leading-8 text-[#0A4E68] uppercase whitespace-wrap max-md:flex-wrap">
        <FaClipboardList  size={50}/>
        <h1 className={`my-auto uppercase font-semibold max-md:max-w-full ${i18n.language === 'ar' ? 'cairo' : 'manrope'} `}>
          {t('About1.projectEvaluation')}
        </h1>
      </div>
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-32 pl-10">
        {scoreCards.map((score,index) => {
          return (
            <div className="w-full flex flex-col gap-6">
              <div className="w-full flex items-center h-auto bg-[#0A4D69] py-4 relative rounded px-12">
                <p className={`  uppercase text-white ${i18n.language==="ar"?"text-right":"text-left"} font-medium leading-7`}>
                 {t(`About1.eval${index+1}title`)}
                </p>
                <div
                  className={`absolute h-18  rounded-full ${i18n.language==="ar"?"-right-12":"-left-12"}  -top-2 p-4 border border-[#0A4D69] bg-white`}
                  style={{
                    height: "webkit-fill-available",
                  }}
                >
                  <p className="  text-center text-sm font-semibold text-[#0A4D69]">
                    {score.points} <br /> <span className="d-block">{t('Points')}</span>
                  </p>
                </div>
              </div>
              <p className="text-[#628D98] text-lg font-medium leading-7">
              {t(`About1.eval${index+1}desc`)}
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ProjectEvaluation;
