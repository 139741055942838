import React, { useRef, useState } from 'react';

import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination as SwiperPagination } from "swiper/modules";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
// Define maximum length for truncation
const MAX_TITLE_LENGTH = 50; // Change according to your needs
const MAX_DESC_LENGTH = 200; // Change according to your needs

const SliderComponent = ({ slides }) => {
    const { t, i18n } = useTranslation();

  const swiperRef = useRef(null);

  // Functions to navigate between slides
  const handlePrev = () => swiperRef.current.swiper.slidePrev();
  const handleNext = () => swiperRef.current.swiper.slideNext();

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };

  return (
    <>
      {slides?.length > 0 && (
        // <div className="bg-white flex flex-col  rounded-b-lg shadow-lg">
          <Swiper
            navigation={{
              prevEl: '.swiper-button-prev',
              nextEl: '.swiper-button-next',
            }}
            pagination={{ type: 'bullets', clickable: true }}
            loop={true}
            modules={[Autoplay, Navigation, SwiperPagination]}
            className="w-[100%] bg-white"
            ref={swiperRef}
          >
            {slides?.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className="flex flex-col items-center">
                  <div className="w-[100%] h-[400px] p-5">
                    <img
                      src={slide.url}
                      alt={slide.title}
                      className="object-cover h-[100%] w-full"
                    />
                  </div>
                  <div className="flex flex-col gap-6 justify-between px-8 ">
                    {/* Truncate Title */}
                    <p className="text-[#013C4A] font-medium text-sm">
                      {truncateText(slide.title, MAX_TITLE_LENGTH)}
                    </p>
                    {/* Truncate Description */}
                    <p className="text-[#084F67] text-xs leading-5 font-light tracking-wide">
                      {truncateText(slide.description?.split('\n')[0], MAX_DESC_LENGTH)}
                    </p>
                    {/* <p>
                        <button
                            className="text-blue-500 text-xs ml-2"
                        >
                            Read More
                        </button>

                    </p> */}
                    <Link to={`/media-centers/${slide._id}`} className="text-blue-500 text-xs "> {t("Read More")}
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            {/* Navigation Buttons */}
            <button
              className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white text-black rounded-full p-2 shadow-lg z-10"
              onClick={handlePrev}
            >
              <IoIosArrowBack size={24} />
            </button>
            <button
              className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white text-black rounded-full p-2 shadow-lg z-10"
              onClick={handleNext}
            >
              <IoIosArrowForward size={24} />
            </button>
          </Swiper>
        // </div>
      )}
    </>
  );
};

export default SliderComponent;
