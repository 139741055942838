import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { t } from "i18next";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import Navbar from "../Components/Nav_Form/Navbar";
import { forgetPasswordApi } from "../Api";
import Header from "../Components/UI/Header";

const ForgetEmailPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email(<>{t("Invalid email")}</>)
        .required(<>{t("Email is required")}</>),
    }),
    onSubmit: async (values) => {
      console.log('email',formik.values.email);
      const email=formik.values.email;
      try {
        setLoading(true);
        
        // console.log(payload)
        const res = await forgetPasswordApi({"email":email});
        formik.values.email='';
        console.log('res',res);
      
        // console.log(res)
        if (res.status === 200 || res.status === 201) {
          toast.success(t(res?.data?.message), { id: "001" });
          //if donar created successfully update isProfile true for furter validation

          setLoading(false);
          
          const id=res.data.data.token;
          // navigate('/thankyou',{replace:true});
        } else {
          // toast.error(res?.response?.data?.message, { id: "err501" });

          setLoading(false);
        }
      } catch (error) {
        toast.error(t(error?.response.data.message), { id: "err501" });
      }
    },
  });

  // Handle form submission

  return (
    <div className="h-screen w-screen overflow-hidden">
    <div className="bg-[#0A4D69]">
      <Header />
    </div>
    <div className="h-[80vh] flex justify-center items-center px-5">
      <div className="h-auto max-w-md pt-8 flex flex-col gap-2 items-center border w-full sm:w-[90%] shadow-lg rounded-md px-4 sm:px-8 py-10">
        <h1 className="text-3xl text-[#263b5c] text-center">{t("Forget Password")}</h1>
        <p className="text-[#989898] text-center">{t("Please Enter Your Email")}</p>
        <form className="mb-4 mt-4 w-full" onSubmit={formik.handleSubmit}>
          <label htmlFor="email" className="text-gray-400">{t("Email")} <span className="text-red-600">*</span></label>
          <input
            type="email"
            name="email"
            id="email"
            className="mt-1 block w-full px-3 py-2 border text-[#d1d5db] rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder={t("Enter Email")}
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <p className="text-red-500">{formik.errors.email}</p>
          ) : null}
          <button
            type="submit"
            className="inline-flex mt-5 mx-auto justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {t(`Reset Password`)}
          </button>
        </form>
      </div>
    </div>
  </div>
  
  
  );
};

export default ForgetEmailPage;
